import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from '../../Backend/ContextProvider'
import Sidebar from '../Sidebar'

export default function AdminPanel() {

  const { user } = useStateContext()


  if (user.role !== 'dev') {
    return <Navigate to="/" />
  }

  return (
    <>
      <div className='flex flex-col text-white xl:flex-row xl:gap-4 h-screen w-screen top-0 left-0 overflow-y-auto content-stretch items-stretch bg-slate-900'>
        <Sidebar />
        <div className='flex p-8 justify-center w-full flex-col '>
          <Outlet />
        </div>
      </div>
    </>
  )
}
