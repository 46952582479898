import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { googleSignIn, logIn } from "../../Backend/FirebaseAuth.jsx";
import { IconBrandGoogle } from "@tabler/icons-react";
import { getAuthErrors } from "../../utils/AuthErrors.jsx";
import Logo from "../../utils/Components/Logo.jsx";
import { toast } from "react-toastify";
import { createDoc, pushData } from "../../Backend/Firestore.jsx";
import { useStateContext } from "../../Backend/ContextProvider.jsx";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { FirebaseConfig } from "../../Backend/firebaseConfig.jsx";
import { InputText } from "../../utils/Components/InputText.jsx";

export default function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { user, setUser } = useStateContext();

    const onLogin = async (ev) => {
        ev.preventDefault();
        try {
            await logIn(email, password);
        } catch (error) {
            toast.warning("Bejelntkezés közben hiba lépett fel" + getAuthErrors(error.code));
        }
    }

    async function googleLogin() {
        await googleSignIn();
        onAuthStateChanged(getAuth(initializeApp(FirebaseConfig)), async (currentuser) => {
            if (currentuser) {

                await pushData("users", currentuser.uid, {}).then(async () => {
                    await setUser({ email: currentuser.email, firstName: currentuser.displayName.split(" ")[0], lastName: currentuser.displayName.split(" ")[1], phoneNumber: currentuser.phoneNumber, avatarUrl: currentuser.photoURL, })
                    await pushData("users", currentuser.uid, { email: currentuser.email, firstName: currentuser.displayName.split(" ")[0], lastName: currentuser.displayName.split(" ")[1], phoneNumber: currentuser.phoneNumber, avatarUrl: currentuser.photoURL, });
                }).catch(error => {
                    toast.warning(getAuthErrors(error.code));
                });
            }
        })
    }


    return (

        <div className="w-full h-screen justify-center items-center flex">
            <div className="p-6 space-y-4 flex flex-col w-screen sm:max-w-lg text-white">

                <div className="flex justify-center items-center py-5 flex-col">
                    <Logo></Logo>
                    <h1 className="text-3xl mt-3 font-bold leading-tight tracking-tight">
                        Login
                    </h1>
                </div>
                <form className="space-y-4" onSubmit={e => e.preventDefault()}>
                    <InputText onChange={e => setEmail(e.target.value)} type="email" placeholder="Email cím" id={"email"} >{email}</InputText>
                    <InputText onChange={e => setPassword(e.target.value)} type="password" placeholder="Jelszó" id={"password"} >{password}</InputText>


                    <button type="submit" onClick={onLogin} className="w-full">Login</button>
                    {/* <div className="flex flex-row items-center">
                        <hr className="w-1/2 opacity-50" />
                        <span className="mx-6 opacity-70">or</span>
                        <hr className="w-1/2 opacity-50" />
                    </div>
                    <button
                        type="submit"
                        className="w-full justify-center flex items-center bg-white  border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        onClick={() => googleLogin()}>
                        <IconBrandGoogle className="mr-3 xs:block hidden" />Login with Google
                    </button> */}
                    <p className="text-sm font-light text-gray-500 ">
                        Don't have an account? <Link to="/signup" className="">Register</Link>
                    </p>
                </form>

            </div>
        </div>
    )
}
