import { initializeApp } from "firebase/app";
import { EmailAuthCredential, EmailAuthProvider, GoogleAuthProvider, createUserWithEmailAndPassword, getAuth, reauthenticateWithCredential, sendEmailVerification, signInWithEmailAndPassword, signInWithPopup, signOut, updateProfile } from "firebase/auth";
import { FirebaseConfig } from "./firebaseConfig.jsx";
import { toast } from "react-toastify";

const auth = getAuth(initializeApp(FirebaseConfig))


export function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
}
export async function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password).then(async (user) => {
        await sendEmailVerification(user.user,)
    });
}
export function logOut() {
    return signOut(auth).then(() => {

    }).catch(err => {
        toast("Hiba lépett fel a kijelentkezés során: " + err.message)
    })
}
export function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
}

export async function reAuth(email, password) {
    var user = auth.currentUser;
    const cred = EmailAuthProvider.credential(email, password)
    return await reauthenticateWithCredential(user, cred)
}

export async function updateUserAuth(data) {

    const auth = getAuth();
    return updateProfile(auth.currentUser, data).then((success) => {
        return success
    }).catch((error) => {
        return error
    });
}