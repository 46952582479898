import React from 'react'
import DatePicker from '../../../utils/Components/DatePicker'

import { InputSelect } from '../../../utils/Components/InputSelect'
import { InputText } from '../../../utils/Components/InputText'
import { BodyShape, Gender } from '../../../utils/Components/HealthDropdowns'
import { signUp } from '../../../Backend/FirebaseAuth'

export default function Health(props) {


    async function onSignup() {
        await signUp(props.user.email, props.user.password);
    }
   


    return (
        <>
            <div className="w-full pr-1">
                <DatePicker yearCount={100} monthName={true} />
            </div>
            <Gender clickAction={(e) => props.updateUserDropdown(e)} value={props.user.gender}></Gender>
            <div className="flex flex-col">
                <div className=" flex">
                    <InputText className={"w-1/2 mr-1 "} placeholder={"Magasság (cm)"} id={"height"} type={"number"} onChange={e => props.updateUserData(e)}>{props.user.height}</InputText>
                    <InputText className={"w-1/2 ml-1 "} placeholder={"Testsúly (kg)"} id={"weight"} type={"number"} onChange={e => props.updateUserData(e)}>{props.user.weight}</InputText>
                </div>

                {props.user.height && props.user.weight ?
                    <div className='mb-6'>
                        Based on your given data, your BMI is: <b>{Math.round(props.user.weight / Math.pow(props.user.height, 2) * 100000) / 10}</b>                        
                    </div>
                    : ""}
            </div>
            <BodyShape value={props.user.bodyShape} clickAction={(e) => props.updateUserDropdown(e)}></BodyShape>
            <div className='flex'>

                <button type="buttom"
                    onClick={(e) => props.setRegStep(props.regStep - 1)}
                    className={"w-1/2 mr-1 "}>
                    Vissza
                </button>
                <button type="submit"
                    disabled={!(props.user.birthday.year < (new Date().getFullYear() - 10) && props.user.height && props.user.weight && props.user.gender && props.user.bodyShape)}
                    onClick={() => onSignup()}
                    className={"w-1/2 ml-1 "}>
                    Regisztrálok
                </button>
            </div>
        </>
    )
}
