import React from 'react'
import { InputText } from '../../utils/Components/InputText'
import { InputSelect } from '../../utils/Components/InputSelect'

export default function Health(props) {
    return (<>
        <div className=' flex flex-row'>
            <InputText className={"w-1/2 mr-1"} type="tel" placeholder={"Magasság (cm)"}>{props.height}</InputText>
            <InputText className={"w-1/2 ml-1"} type="tel" placeholder={"Súly (kg)"}>{props.weight}</InputText>
        </div>
        <InputSelect defaultValue={props.env} placeholder={"Fényviszonyok"} id="env" cta={"Milyen környezetben komfortos a munkavégzés?"}>
            <option onClick={(e) => props.updateUserDropdown(e)} value={0}>Nagyon sötét</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={1}>Sötét</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={2}>Természetes</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={3}>Világos</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={4}>Nagyon Világos</option>
        </InputSelect>
        <InputSelect defaultValue={props.foodAmount} placeholder={"Kaja mennyiség"} id="foodAmount" cta={"Adja meg, hogy átlagosan mennyit eszik?"}>
            <option onClick={(e) => props.updateUserDropdown(e)} value={0}>Nagyon keveset</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={1}>Keveset</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={2}>Átlagos adagot</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={3}>Sokat</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={4}>Nagyon sokat</option>
        </InputSelect>
        <InputSelect defaultValue={props.frontSens} placeholder={"Frontérzékenység"} id="frontSens" cta={"Adja meg mennyire frontérzákeny?"}>
            <option onClick={(e) => props.updateUserDropdown(e)} value={0}>Egyáltalán nem vagyok frontérzákeny</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={1}>A nagyobb változásokat megérzem</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={2}>Elég egy kisebb frontváltozás is hogy megérezzem</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={3}>A migrén lassan a szobatársam</option>
        </InputSelect>
        <InputSelect defaultValue={props.sweat} placeholder={"Izzadékonyság"} id="sweat" cta={"Adja meg, hogy mennyire izzadós"}>
            <option onClick={(e) => props.updateUserDropdown(e)} value={0}>Egyáltalán nem vagyok izzadós</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={1}>Nagyobb mozgás enyhén megizzaszt</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={2}>Egy nyári napon is megizzadok</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={3}>Kisebb mozgást is megérzek</option>
            <option onClick={(e) => props.updateUserDropdown(e)} value={4}>Mindig izzadok</option>
        </InputSelect>
    </>
    )
}
