export default function color() {
    const colors = [
        ["949398", "F4DF4E"],
        ["FC766A", "5B84B1"],
        ["5F4B8B", "E69A8D"],
        ["42EADD", "CDB599"],
        ["000000", "FFFFFF"],
        ["00A4CC", "F95700"],
        ["00203F", "ADEFD1"],
        ["606060", "D6ED17"],
        ["2C5F2D", "97BC62"],
        ["00539C", "EEA47F"],
        ["0063B2", "9CC3D5"],
        ["101820", "FEE715"],
        ["CBCE91", "EA738D"],
        ["89ABE3", "FCF6F5"],
        ["101820", "F2AA4C"],
        ["A07855", "D4B996"],
        ["195190", "A2A2A1"],
        ["603F83", "C7D3D4"],
        ["2BAE66", "FCF6F5"],
        ["FAD0C9", "6E6E6D"],
        ["2D2926", "E94B3C"],
        ["990011", "FCF6F5"]
    ];

    const randomIndex = Math.floor(Math.random() * colors.length);

    // Get the color pair at the random index
    const randomColorPair = colors[randomIndex];

    function shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    // Shuffle the order of the colors within the pair
    const shuffledPair = shuffle(randomColorPair);

    // Return the shuffled pair
    return `background=${shuffledPair[0]}&color=${shuffledPair[1]}`;
}
