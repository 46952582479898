
import React, { useEffect, useState } from 'react'
import { getData, getLastData, getUpdatingData } from '../../Backend/Firestore';
import Buttons from './Buttons';
import Thermostat from "./Thermostat"




export default function Dashboard() {

    const [modes, setModes] = useState({});
    const [currentTempData, setCurrentTempData] = useState();
    const [refresherDashboard, setRefresherDashboard] = useState();

    useEffect( () => {
        getLastData("/temperature", "timestamp", "desc", 1).then(data => {
            if (data) {
                setCurrentTempData(data)
            }
        })
        getData("utils", "modes").then(async snapshot => {
            setModes(snapshot)
        })
    },[refresherDashboard])

    return (

        <div className='text-center relative flex flex-col items-stretch h-full md:w-1/2 lg:w-1/3'>
                <Thermostat currentTempData={{...currentTempData}} modes={{...modes}}/>
                <Buttons setRefresherDashboard={setRefresherDashboard} currentTempData={{...currentTempData}} modes={{...modes}}/>
        </div>
    )
}
