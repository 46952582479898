import { IconEye, IconEyeClosed } from '@tabler/icons-react'
import React, { useState } from 'react'

export const InputText = ({ type, id,onBlur, onFocus, onChange, className, placeholder, children,errorMessage, required, disabled}) => {

  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className={'formInput group ' + className}>
      <input
      className='userText pb-0 peer'
      placeholder=''
      type={type === undefined || showPassword ? "text" : type}
      id={id} name={id}
      defaultValue={children}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      required={required}
      disabled={disabled}
      />
      <label className="peer-focus:top-2 pointer-events-none peer-focus:pl-0 top-3 pl-0 peer-focus:text-primary-200 peer-placeholder-shown:pl-2 peer-placeholder-shown:top-10" htmlFor="firstName">{placeholder}</label>
      {type==="password"?
      <span className='absolute right-0 top-0 p-2 hover:bg-primary-50/30'
      onClick={()=> setShowPassword(!showPassword)}>{showPassword?<IconEye/>:<IconEyeClosed/>}</span>:null}
      {errorMessage?<p className="mt-2 text-rose-100 text-xs">
        {errorMessage}
      </p>:null}
    </div>
  )
}
