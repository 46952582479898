import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStateContext } from "../../Backend/ContextProvider";
import Logo from "../../utils/Components/Logo";
import PersonalInfo from "./RegSteps/PersonalInfo";
import CompanyInfo from "./RegSteps/CompanyInfo";
import Health from "./RegSteps/Health";
import color from "../../utils/colorGenerator";

export default function Signup() {
    const [regStep, setRegStep] = useState(1)


    const { user, setUser } = useStateContext();

    function updateUserData(e) {
        const name = e.target.name;
        const data = {};
        data[name] = e.target.value
        setUser(user => ({
            ...user,
            ...data
        }));
    }
    useEffect(() => {
        setUser({ "birthday": { year: new Date().getFullYear()-20, month: 1, day: 1 },
    
        'age': 20,
        'avatarUrl': `https://ui-avatars.com/api/?name=$Kovács Béla&size=512&${color()}`,
        'bodyShape': 1,
        'phoneNumber': +36207705315,
        'company': "7Digits",
        'companyPassword': -475204515,
        'Password': "Valaki2001",
        'PasswordAgain': "Valaki2001",
        'email': "xafihe2444@kravify.com",
        'env': 2,
        'firstName': "Kovács",
        'foodAmount': 2,
        'frontSens': 1,
        'height': 180,
        'lastName': "Béla",
        'gender': 1,
        'sweat': 0,
        'username': "KovaBe",
        'weight': 80,
    })
    }, [])
    useEffect(() => {

    }, [])

    

    const onSubmit = async (event) => {
        // prevent redirect
        event.preventDefault();
    }

    async function updateUserDropdown(e) {
        const name = e.name;
        await setUser(prevData => ({
            ...prevData,
            [name]: e.value
        }));
    }


    return (
        <div className="w-full h-screen justify-center items-center flex">
            <div className="p-6 space-y-4 flex flex-col w-screen sm:max-w-[500px] sm:bg-primary-950 rounded-lg sm:border-primary-800 sm:border-2 text-white">
                <div className="flex justify-center items-center py-5 flex-col">
                    <Logo></Logo>
                    <h1 className="text-3xl font-bold leading-tight tracking-tight pb-10">
                        Register
                    </h1>
                </div>
                <form className="" action="#" onSubmit={onSubmit}>
                    {regStep === 1 ? <PersonalInfo user={user} regStep={regStep} setRegStep={(x) => setRegStep(x)} updateUserDropdown={w => updateUserDropdown(w)} updateUserData={(e) => updateUserData(e)} /> : ""}
                    {regStep === 2 ? <CompanyInfo user={user} regStep={regStep} setRegStep={(x) => setRegStep(x)} setUser={(e)=> setUser(e)} updateUserDropdown={w => updateUserDropdown(w)} updateUserData={(e) => updateUserData(e)} /> : ""}
                    {regStep === 3 ? <Health user={user} regStep={regStep} setRegStep={(x) => setRegStep(x)} updateUserDropdown={w => updateUserDropdown(w)} updateUserData={(e) => updateUserData(e)} /> : ""}
                    <p className="text-sm font-light text-primary-50">
                        Already have an account? <Link to="/login" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login</Link>
                    </p>
                </form>
            </div>
        </div>
    )

}