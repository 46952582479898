import React, { useEffect, useState } from 'react'
import { Form, Link, useLoaderData, useParams } from 'react-router-dom'
import { InputText } from '../../../../utils/Components/InputText'
import { geocode, stringToHash } from '../../../../utils/Getters'
import { getContainingDocumentName, deleteData, getCollectionData, pushData } from '../../../../Backend/Firestore'
import { Map } from '../../../../utils/Components/Map/Map'
import { IconCurrentLocation, IconSearch } from '@tabler/icons-react'
import { toast } from 'react-toastify'

export default function EditCompany() {
    const company = useLoaderData().companies
    const members = useLoaderData().companyMembers
    const [filter, setFilter] = useState(null)
    const [editCompany, setEditCompany] = useState(null)
    const { companyId } = useParams()

    useEffect(() => {
        setEditCompany(company)
    }, [company])

    const [address, setAddress] = useState("")
    const [addressList, setAddressList] = useState(null)
    const [position, _setPosition] = useState(null)
    const [map, setMap] = useState(null)
    async function setPosition(e, index) {

        if (typeof index === "number") {
            const address = addressList[index]
            await setEditCompany(company => ({
                ...company,
                location: {
                    coordinates: {
                        latitude: e.lat,
                        longitude: e.lng,
                    },
                    county: address.components.county || null,
                    city: address.components.city || null,
                    country: address.components.country || null,
                    district: address.components.borough || null,
                    suburb: address.components.suburb || null,
                    house_number: address.components.house_number || null,
                    street: address.components.road || null,
                    zipCode: address.components.postcode || null,
                }
            }))
            setAddress(address.formatted)
        } else if (index === "located") {
            geocode(e.lat + "," + e.lng).then(async (res) => {
                const addr = res[0]
                console.log(addr)
                await setEditCompany(company => ({
                    ...company,
                    location: {
                        coordinates: {
                            latitude: e.lat,
                            longitude: e.lng,
                        },
                        county: addr.components.county || null,
                        city: addr.components.city || null,
                        country: addr.components.country || null,
                        district: addr.components.borough || null,
                        suburb: addr.components.suburb || null,
                        house_number: addr.components.house_number || null,
                        street: addr.components.road || null,
                        zipCode: addr.components.postcode || null,

                    }
                }))
                await setAddress(addr.formatted)
            })
        }
        await _setPosition(e)
    }

    function onAddressSearch() {
        setAddressList("loading")
        const delayDebounceFn = setTimeout(() => {
            geocode(address).then((result) => {
                setAddressList(result)
            })
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }


    async function onCompanyChange(e) {
        const varName = e.target.id
        setEditCompany(company => ({
            ...company,
            location: {
                ...company.location,
                [varName]: e.target.value
            }
        }))
    }

    return (

        <>
            <Form >
                <div className='flex gap-2'>
                    <InputText placeholder={"Vezetéknév"}>{company.name}</InputText>
                    <InputText placeholder={"Azonosító"}>{useParams().companyId}</InputText>
                </div>
                <div className='flex gap-2'>
                    <InputText type="password" placeholder={"Felhasználó jelszó"} disabled>{stringToHash(company.name, "user")}</InputText>
                    <InputText type="password" placeholder={"Admin jelszó"} disabled>{stringToHash(company.name, "admin")}</InputText>
                </div>
                <div className='flex gap-2 flex-row'>
                    <InputText id={"address"} onChange={(e) => setAddress(e.target.value)} placeholder="Keresés" className={"!mb-0"}>{company.address}</InputText>
                    <div className='flex gap-2'>
                        <button type='text' onClick={() => onAddressSearch()} ><IconSearch /></button>
                        <button type='text' onClick={() => map.locate()}><IconCurrentLocation /></button>
                    </div>
                </div>
                <div className='max-h-[250px] min-h-[200px] w-full overflow-hidden rounded-lg'>
                    <Map pos={position} map={map} setMap={setMap} setPosition={setPosition} >
                    </Map>
                </div>
                <div className='flex gap-2'>
                    <InputText placeholder={"Hosszúság"} disabled>{company.location.coordinates.longitude}</InputText>
                    <InputText placeholder={"Szélesség"} disabled>{company.location.coordinates.latitude}</InputText>
                </div>
                <div className='flex gap-2'>
                    <InputText id={"country"} onChange={(e) => { onCompanyChange(e) }} placeholder="Ország">{company.location.country}</InputText>
                    <InputText id={"county"} onChange={(e) => { onCompanyChange(e) }} placeholder="Megye">{company.location.county}</InputText>
                </div>
                <div className='flex gap-2'>
                    <InputText required id={"zipCode"} onChange={(e) => { onCompanyChange(e) }} placeholder="Irányítószám">{company.location.zipCode}</InputText>
                    <InputText required id={"city"} onChange={(e) => { onCompanyChange(e) }} placeholder="Város">{company.location.city}</InputText>
                </div>
                <div className='flex gap-2'>
                    <InputText id={"district"} onChange={(e) => { onCompanyChange(e) }} placeholder="Kerület">{company.location.district}</InputText>
                    <InputText id={"suburb"} onChange={(e) => { onCompanyChange(e) }} placeholder="Városrész">{company.location.suburb}</InputText>
                </div>
                <div className='flex gap-2 flex-col sm:flex-row '>
                    <InputText required id={"street"} onChange={(e) => { onCompanyChange(e) }} className={"!sm:w-1/2 w-full"} placeholder="Utca">{company.location.street}</InputText>
                    <div className='flex gap-2 !sm:w-1/2 w-full'>
                        <InputText required id={"house_number"} onChange={(e) => { onCompanyChange(e) }} className={"!w-1/3"} placeholder="Házszám">{company.location.house_number}</InputText>
                        <InputText id={"floor"} onChange={(e) => { onCompanyChange(e) }} className={"!w-1/3"} placeholder="Szint">{company.location.floor}</InputText>
                        <InputText id={"door"} onChange={(e) => { onCompanyChange(e) }} className={"!w-1/3"} placeholder="Ajtó">{company.location.door}</InputText>
                    </div>
                </div>
            </Form>
            <div className='gap-2 flex mb-2 flex-row-reverse'>
                <button onClick={() => pushData("companies", "/" + companyId, editCompany).then(()=> toast(company.name + " sikeresen frissítve"))} type='submit' className='success'>Mentés</button>
                <button className="warning" onClick={() => setEditCompany(company)}>Elvetés</button>
            </div>
            <div className='gap-2 flex mb-2'>
                <button onClick={() => setFilter(null)}>Nincs szűrés</button>
                <button onClick={() => setFilter("user")}>User</button>
                <button onClick={() => setFilter("admin")}>Admin</button>
                <button onClick={() => setFilter("dev")}>Fejlesztő</button>
            </div>
            <div className='grid grid-flow-row sm:grid-cols-2 lg:grid-cols-3 gap-2'>
                {members ?

                    members.map((member) => {
                        const memberId = Object.keys(member)
                        member = Object.values(member)[0]
                        return (
                            <Link key={memberId} className={((filter == null || filter == member.role) ? "" : " hidden") + ' flex gap-4 items-center p-2 hover:pl-3 transition-all rounded-lg bg-primary-700 text-primary-50 hover:no-underline'} to={"/admin/user/edit/" + memberId}>
                                <img className='rounded-full aspect-square h-[50px]' src={member.avatarUrl} alt={member.firstName + " " + member.lastName + " profilképe"} />
                                <div className="flex flex-col">
                                    <span>{member.firstName} {member.lastName} - <span className='opacity-80 font-thin'>{member.role}</span></span>
                                    <span className=' font-extralight text-sm'>{member.email}</span>
                                </div>
                            </Link>
                        )
                    })
                    : null
                }
            </div>
        </>

    )
}
