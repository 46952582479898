import React, { useEffect, useState } from 'react'
import { Link, useLoaderData, } from 'react-router-dom';
import { stringToHash } from '../../../../utils/Getters';
import { InputText } from '../../../../utils/Components/InputText';
import { IconPlus } from '@tabler/icons-react';
import CreateCompany from './createCompany';

export default function Companies() {

    const [showModal, setShowModal] = useState(false)
    const [companyList, setCompanyList] = useState([])
    const loaderData = useLoaderData()


    useEffect(() => {
        setCompanyList(loaderData)
    }, [showModal])


    return (
        <div>
            <div className='flex justify-between'>
                <span className='text-3xl font-bold tracking-wide'>Cégek</span>
                <button className='flex justify-around' onClick={() => setShowModal(!showModal)}><IconPlus />Új cég</button>
            </div>

            {companyList ?

                <div className="relative sm:rounded-lg">

                    <table className="w-full mt-4 text-sm text-left rtl:text-right  text-gray-400">
                        <thead className="text-xs  uppercase bg-gray-700 text-gray-400">
                            <tr>
                                <th scope="col" className="p-4  table-hidden-cell">
                                    <div className="flex items-center">
                                        <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600  rounded  focus:ring-blue-600 ring-offset-gray-800 focus:ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600" />
                                        <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3 table-hidden-cell">
                                    Cég neve
                                </th>
                                <th scope="col" className="px-6 py-3 table-hidden-cell">
                                    Felhasználó jelszó
                                </th>
                                <th scope="col" className="px-6 py-3 table-hidden-cell">
                                    Admin jelszó
                                </th>
                                <th scope="col" className="px-6 py-3 table-hidden-cell">
                                    Azonosító
                                </th>
                                <th scope="col" className="px-6 py-3 table-hidden-cell">
                                    Tagok
                                </th>
                                <th scope="col" className="px-6 py-3 table-hidden-cell">

                                </th>
                            </tr>
                        </thead>
                        <tbody className='flex md:table-row-group flex-wrap flex-shrink'>

                            {companyList.map((company, index) => {
                                return (
                                    <tr key={index} className="bg-gray-800 border-gray-700 border-b-2 flex flex-col w-full md:table-row hover:bg-gray-600">
                                        <td className="w-4 p-4  table-hidden-cell">
                                            <div className="flex items-center ">
                                                <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600  rounded focus:ring-blue-600 ring-offset-gray-800 focus:ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600" />
                                                <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <th scope="row" className="px-6 pt-4 md:py-4 flex flex-col">
                                            <span className='md:text-base text-xl  font-medium whitespace-nowrap text-white'>{Object.values(company)[0].name}</span>
                                        </th>
                                        <td className="px-6 py-4 table-cell">
                                            <span>Felhasználó jelszó: </span>
                                            <InputText disabled type="password" className={"!m-0"}>
                                                {Object.values(company)[0].name ? stringToHash(Object.values(company)[0].name, "user") : null}
                                            </InputText>
                                        </td>
                                        <td className="px-6 py-4 table-cell">
                                            <span>Admin jelszó: </span>
                                            <InputText disabled type="password" className={"!m-0"}>
                                                {Object.values(company)[0].name ? stringToHash(Object.values(company)[0].name, "admin") : null}
                                            </InputText>
                                        </td>
                                        <td className="px-6 py-4 table-cell" title={Object.keys(company)[0]}>
                                            <span>Azonosító: </span>
                                            {Object.keys(company)[0]}
                                        </td>
                                        <td className="px-6 py-4 table-cell" title={Object.keys(company)[0]}>
                                            <span>Tagok: </span>
                                            {Object.values(company)[0].users ? Object.values(Object.values(company)[0].users).length : null}
                                        </td>
                                        <td className="px-6 py-4">
                                            <Link to={"/admin/company/edit/" + Object.keys(company)[0]} className="font-medium text-blue-500 hover:underline">Szerkesztés</Link>
                                        </td>
                                    </tr>)
                            })}

                        </tbody>
                    </table>
                    <CreateCompany companies={companyList} showModal={showModal} setShowModal={setShowModal}/>
                </div>

                : null}
        </div>
    )
}