import React, { useEffect, useState } from 'react'
import { InputText } from '../../utils/Components/InputText'
import { getContainingDocumentName, getData } from '../../Backend/Firestore'


export default function Company(props) {

    const [company, setCompany] = useState();
    const [role, setRole] = useState();

    useEffect(() => {

        getData("companies",props.company).then(async (data) => {
            await setCompany(data)
            await setRole(data.users[props.userAuth.uid])
        })

    }, [])



    return (
        <>
            {company ? <>
                <div className='flex items-center justify-center'>
                    <InputText disabled className={"xs:w-2/3 w-3/5"} placeholder={"Company"} id="company">{company.name}</InputText>
                    <div className='flex flex-col text-center mb-5 w-2/5 xs:w-1/3 justify-center items-center'>
                        <span>Cég azonosító:</span>
                        <span>{props.company}</span>
                    </div>
                </div>
                <InputText disabled placeholder={"Felhasználói jogosultság"} id="role">{role}</InputText>
            </>
                : null}
        </>
    )
}
