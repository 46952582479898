
export function stringToHash(string, role) {

    let hash = 0;

    const hashAmount = role == "user" ? 5 : (role == "admin" ? 10 : 25)

    if (string.length == 0) return hash;

    for (let i = 0; i < string.length; i++) {
        let char = string.charCodeAt(i);
        hash = ((hash << hashAmount) - hash) + char;
        hash = hash & hash;
    }

    return hash;
}
/**
 * 
 * @param {String} address 
 */
export async function geocode(address) {
    const url = `https://opencage-geocoder.p.rapidapi.com/geocode/v1/json?q=${address}&key=629fbd41f3004da8a1abcc9867a87acd&language=hu`;
    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': 'c71741ccc3msh3ba9ade8ddd4017p18c3f3jsnfc37a4437276',
            'X-RapidAPI-Host': 'opencage-geocoder.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        const result = await response.text();
        return JSON.parse(result).results
    } catch (error) {
        console.error(error);
    }
}
