import React, { useState } from 'react'
import { InputText } from '../../../utils/Components/InputText';
import { checkUsed } from '../../../Backend/Firestore'

export default function PersonalInfo(props) {

    const [ph, setPH] = useState(false)
    const [usernameUsed, setUsernameUsed] = useState(false)
    const [emailUsed, setEmailUsed] = useState(false)

    function passwordText(d) {
        var passwordStrength = 0;
        try {

            if (props.user.password.length > 6) {
                passwordStrength++
            }
            if ((props.user.password.match(/[0-9]/g)) && props.user.password.length > 6) {
                passwordStrength++
            }
            if ((props.user.password.match(/(?=.*[a-z])(?=.*[A-Z])/g)) && props.user.password.length > 6) {
                passwordStrength++
            }
            if (props.user.password.match(/[-_.!#$%&? ]/g) && props.user.password.length > 6) {
                passwordStrength++
            }
            if (props.user.password.length > 10) {
                passwordStrength++
            }

            if (d === 'get') {
                return passwordStrength
            } else {
                switch (passwordStrength) {
                    case 0:
                        return 'Password should be at least 6 characters long'
                    case 1:
                        return 'Weak password'
                    case 2:
                        return 'Avarage password'
                    case 3:
                        return 'Strong password'
                    case 4:
                        return 'Very strong password'
                    case 5:
                        return 'Extreme strong password'
                    default:
                        break;
                }
            }
        } catch (e) { return 'Password should be at least 6 characters long' }
    };





    return (
        <>
            <div className="flex justify-around">
                <InputText required onChange={e => props.updateUserData(e)} className="w-1/2 mb-0 pr-1" placeholder="Vezetéknév" id={"firstName"} >{props.user.firstName}</InputText>
                <InputText required onChange={e => props.updateUserData(e)} className="w-1/2 mb-0 pl-1" placeholder="Keresztnév" id={"lastName"} >{props.user.lastName}</InputText>

            </div>
            <InputText
                required
                onChange={e => props.updateUserData(e)}
                errorMessage={usernameUsed ? "A felhasználónév már használatban van" : ""}
                onBlur={async () => await setUsernameUsed(await checkUsed("users", "username", props.user.username))}
                placeholder="Felhasználónév"
                id={"username"}
            >{props.user.username}</InputText>
            <InputText
                required
                onChange={e => props.updateUserData(e)}
                type={"email"}
                placeholder="Email cím"
                id={"email"}
                onBlur={async () => await setEmailUsed(await checkUsed("users", "email", props.user.email))}
                errorMessage={emailUsed ? "Az email cím már használatban van" : ""}
            >{props.user.email}</InputText>
            <div className="formInput group">
                <InputText required onChange={e => props.updateUserData(e)} type={"password"} placeholder="Jelszó" id={"password"} onBlur={() => setPH(false)} onFocus={() => setPH(true)}>{props.user.password}</InputText>
                <div className={(ph ? "block" : "hidden") + " bg-primary-900 border-primary-800 border-[1px] overflow-hidden items-center justify-center transition-all m-3 p-2 pt-3 rounded-lg"}>
                    <div clasesName={"flex flex-col items-start"}>
                        {passwordText()}
                        <div className="flex">
                            <hr className={(passwordText("get") > 0 ? "border-lime-500" : " border-gray-300") + " border-2 rounded-full w-1/4 my-2 mr-1"} />
                            <hr className={(passwordText("get") > 1 ? "border-lime-500" : " border-gray-300") + " border-2 rounded-full w-1/4 my-2 m-1"} />
                            <hr className={(passwordText("get") > 2 ? "border-lime-500" : " border-gray-300") + " border-2 rounded-full w-1/4 my-2 m-1"} />
                            <hr className={(passwordText("get") > 3 ? "border-lime-500" : " border-gray-300") + " border-2 rounded-full w-1/4 my-2 m-1"} />
                            <hr className={(passwordText("get") > 4 ? "border-lime-500" : " border-gray-300") + " border-2 rounded-full w-1/4 my-2 ml-1"} />
                        </div>
                        A jelszó nehezebben feltörhető, ha van:
                        <ul className="pl-7">
                            <li className={((false || (props.user.password && props.user.password.match(/(?=.*[a-z])(?=.*[A-Z])/g))) ? "text-lime-200 line-through " : " text-rose-100") + " list-disc"}>Kis és nagy betű </li>
                            <li className={((false || (props.user.password && props.user.password.match(/[0-9]/g))) ? "text-lime-200 line-through " : " text-rose-100") + " list-disc"}>Legalább egy szám</li>
                            <li className={((false || (props.user.password && props.user.password.match(/[-_.!#$%&? ]/g))) ? "text-lime-200 line-through " : " text-rose-100") + " list-disc"}>Legalább egy szimbólum (<b>-_. !#$%&?</b>)</li>
                            <li className={((false || (props.user.password && props.user.password.length > 10)) ? "text-lime-200 line-through " : " text-rose-100") + " list-disc"}>10 karakter hosszú</li>
                        </ul>
                    </div>
                </div>
            </div>

            <InputText required onChange={e => props.updateUserData(e)} type={"password"} placeholder="Jelszó megerősítése" id={"passwordAgain"} >{props.user.passwordAgain}</InputText>
            <button type="submit"
            disabled={!(props.user.firstName && props.user.lastName && props.user.email && props.user.username && props.user.password && (props.user.password.length > 5) && (props.user.passwordAgain === props.user.password) && (!emailUsed) && (!usernameUsed))}
                onClick={() => props.setRegStep(props.regStep+1)}
                className="w-full">
                Tovább
            </button>
        </>
    )
}
