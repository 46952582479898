export function getAuthErrors(code) {
    switch (code) {
        case "auth/missing-password":
            return ("A jelszó mező kitöltése kötelező")
        case "auth/nvalid-email":
            return ("Hibás email cím")
        case "auth/invalid-credential":
            return ("Hibás email cím vagy jelszó")
        case "auth/missing-password":
            return ("A jelszó megadása kitöltése kötelező")
        case "auth/invalid-email":
            return ("Hibás email cím")
        case "auth/email-already-in-use":
            return ("Az email cím már használatban van")
        case "auth/invalid-credential":
            return ("Hibás email cím vagy jelszó")
        case "auth/email-already-exists":
            return ("Az email cím már használatban van")
        case "auth/internal-error":
            return ("Hiba lépett fel a rendszerünkben, próbáld újra később.")
        case "auth/weak-password":
            return ("A jelszónak legalább 6 karakternek kell lennie")
        case "auth/too-many-requests":
            return ("Túl sokszor próbálkoztál, próbáld kicsit később")
        default:
            return (code)
    }
}
