import { IconX } from '@tabler/icons-react'
import React, { useState } from 'react'


export const InputSelect = ({ className, defaultValue, id, noValue, placeholder, cta, children }) => {
    const [pos, setPos] = useState(false)
    const [showList, setShowList] = useState(false)
    const [selected, setSelected] = useState({})

    function calculatePos(e) {
        const mousePos = e.clientY
        const screenHeight = document.body.clientHeight
        if (mousePos + 250 > screenHeight) {
            setPos(true)
        } else { setPos(false) }
    }

    function getLabel() {
        if (defaultValue !== null) {
            return Object.keys(children).map((child) => {
                if (children[child].props.value == defaultValue) {
                    return children[child].props.children
                }
            })
        }
        else if (selected.label) {
            return selected.label
        } else {
            return cta
        }
    }


    return (
        <>
            <div className={(showList ? "" : "hidden") + ' bg-black/40 z-20 transition-all absolute w-screen h-screen top-0 left-0'}
                onClick={(e) => {
                    setShowList(false);
                }}
            ></div>
            <div
                onClick={(e) => {
                    calculatePos(e)
                    setShowList(!showList);
                }}
                id={id}
                value={selected.value || defaultValue}
                className={(showList ? "hover:bg-primary-200/5 z-30" : "") + " inline-block relative p-2.5 mb-4 px-0 w-full text-sm text-primary-50 bg-transparent border-0 border-b-2 border-gray-200 appearance-none hover:bg-primary-200/5 transition-all " + className}>
                <span className={
                    ((selected.label === undefined) && !defaultValue ? "opacity-70" : "opacity-100") + ' ml-2.5'}>
                    {
                        getLabel()
                    }

                </span>
                <div className={(showList ? 'grid ' : "hidden ") + (pos ? "bottom-10 " : "") + 'overflow-auto  max-h-[250px] z-50 mt-2.5 grid-flow-row absolute w-full'}>
                    {Object.keys(children).map((child) => {
                        return <span
                            {...children[child].props}
                            name={id}
                            key={child}
                            onClick={async () => {
                                const x = Object.assign({ "name": id }, children[child].props)
                                await setSelected({ "label": children[child].props.children, "value": children[child].props.value })
                                children[child].props.onClick(x);
                            }}
                            className={(selected.value === children[child].props.value ? "bg-slate-600" : "") + " bg-slate-800 border-[1px] border-slate-700/40 cursor-pointer hover:bg-slate-700 p-2"}>
                            {children[child].props.children}
                        </span>
                    })}
                </div>
                <label className={" absolute text-sm left-0  text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:font-medium peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0"}>
                    {placeholder}
                </label>
                {(selected.label !== undefined) && !noValue ?
                    <label
                        className={"absolute top-0 right-0 p-2 hover:text-rose-500 hover:bg-rose-50/30"}
                        onClick={() => {
                            setShowList(false)
                            setSelected({});
                        }}
                    ><IconX /></label>
                    :
                    null
                }
            </div>
        </>
    )
}
