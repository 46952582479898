import { Navigate, createBrowserRouter } from 'react-router-dom';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import Feedback from '../pages/Feedback';
import Dashboard from '../pages/Dashboard';
import DefaultLayout from '../components/Pages/DefaultLayout.jsx';
import GuestLayout from '../components/Pages/GuestLayout.jsx';
import User from '../pages/User';
import AdminPanel from '../components/Pages/AdminPanel';
import { checkExists, getCollectionData, getData } from '../Backend/Firestore'
import AdminDashboard from '../pages/Admin/AdminDashboard';
import EditUser from '../pages/Admin/Components/Users/EditUser';
import Users from '../pages/Admin/Components/Users/Users';
import Companies from '../pages/Admin/Components/Companies/Companies';
import EditCompany from '../pages/Admin/Components/Companies/EditCompany';

const router = createBrowserRouter([
    {
        path: "/",
        element: <GuestLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/signup',
                element: <Signup />
            },
        ]
    },
    {
        path: "/",
        element: <DefaultLayout />,
        children: [
            {
                path: '/',
                element: <Dashboard />
            },
            {
                path: '/user',
                element: <User />
            },
            {
                path: '/dashboard',
                element: <Dashboard />
            },
            {
                path: '/feedback',
                element: <Feedback />,
                loader: (async () => {
                    return await getData("utils", "/moods").then(async data => {
                        return await (data)
                    })
                })
            },

        ]
    },
    {
        path: "/",
        element: <AdminPanel />,
        children: [
            {
                path: "/admin",
                element: <AdminDashboard />,
                loader: (async () => {
                    return getCollectionData("users").then(async users => {
                        return await (users)
                    })
                })
            },
            {
                path: '/admin/users',
                element: <Users />,
                loader: (async () => {
                    return getCollectionData("users").then(async users => {
                        return await (users)
                    })
                })
            },
            {
                path: '/admin/companies',
                element: <Companies />,
                loader: (async () => {
                    return getCollectionData("companies").then(async users => {
                        return await (users)
                    })
                })
            },
            {
                path: '/admin/user/edit/:userId',
                element: <EditUser />,
                loader: (async (params) => {
                    return getData("users", `${params.params.userId}`).then(async user => {
                        return await (user)
                    })
                })
            },
            {
                path: '/admin/company/edit/:companyId',
                element: <EditCompany />,
                loader: (async (params) => {

                    const [companies, companyMembers] = await Promise.all([
                        getData("companies", `${params.params.companyId}`).then(async company => {
                            return await company
                        }),
                        checkExists("users", "company", `${params.params.companyId}`).then(async company => {
                            return await company
                        })
                      ]);
                    return { companies, companyMembers}
                })
            },
        ]
    },
    {
        path: '*',
        element: <Navigate to='/' />
    }

])



export default router