import React from 'react'
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet'
import currentLocation from "../../../assets/location.svg"
import L from "leaflet"
import 'leaflet/dist/leaflet.css'

export const Map = ({pos, setMap,  setPosition }) => {
  const icon = L.icon({
    iconUrl: currentLocation,
    iconSize: [40, 40],
    iconAnchor: [20, 20],
  })


  const Handler = ()=> {
    const map = useMapEvents({
      async click(e) {
        await setPosition(e.latlng, "located")
        map.flyTo(e.latlng, 18)
      },
      async locationfound(e) {
        await setPosition(e.latlng, "located")
        map.flyTo(e.latlng, 18)
      }
    })
  }



  return (
    <MapContainer
      center={[47.4979, 19.0402]}
      zoom="9"
      scrollWheelZoom={true}
      ref={setMap}
      className='max-h-[250px] min-h-[200px]'
    >
      <Handler/>
      <TileLayer

        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        
      />
      {
        pos === null? null:
        <Marker
        icon={icon}
        position={pos}>
      </Marker>
        }
    </MapContainer>
  )
}