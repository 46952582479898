import React from 'react'
import { InputText } from '../../utils/Components/InputText'
import { InputSelect } from '../../utils/Components/InputSelect'
import DatePicker from '../../utils/Components/DatePicker'
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react'

export default function Personal(props) {


    return (
        <>
            <InputText onChange={(e) => props.updateUserText(e)} className="w-full" placeholder="Felhasználónév" id={"username"} >{props.username}</InputText>
            <div className='flex items-center'>
                <InputText onChange={(e) => props.updateUserText(e)} type="email" className="w-full" placeholder="Email" id={"email"} >{props.userAuth.email}</InputText>
                <span className='h-full aspect-square justify-center p-2 flex' title={props.userAuth.emailVerified ? "Ez az email hitelesítve van" : "Ez az email nincs hitelesítve"}>
                    {props.userAuth.emailVerified ? <IconCircleCheck className=' text-emerald-500' /> : <IconCircleX className=' text-rose-500' />}
                </span>
            </div>
            <InputText onChange={(e) => props.updateUserText(e)} type="tel" className="w-full" placeholder="Telefonszám" id={"phoneNumber"} >{props.phoneNumber}</InputText>
            <DatePicker className="" monthName={true} />
            <InputSelect defaultValue={props.gender} placeholder={"Nem"} id="gender" cta={"Válassza ki a nemét"}>
                <option onClick={(e) => props.updateUserDropdown(e)} value={0}>Nő</option>
                <option onClick={(e) => props.updateUserDropdown(e)} value={1}>Férfi</option>
                <option onClick={(e) => props.updateUserDropdown(e)} value={2}>Egyéb</option>
            </InputSelect>


        </>
    )
}
