import Logo from '../../assets/LogoSmall.svg'

export default function Loader(props) {



  return (
    <div className={'w-1/4' + props.className}>

    <svg id="Réteg_2" data-name="Réteg 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.19 73.63">
      <g id="Réteg_1" data-name="Réteg 1">
        <g>
          <path style={{fill:"white"}} className='animate-[loader_3000ms_linear_infinite_500ms]' d="M67.19,46.89c-.23,3.04-1.08,6.12-2.55,9.26-1.47,3.14-3.55,6.03-6.25,8.68-2.69,2.65-6.02,4.78-9.99,6.39s-8.57,2.41-13.82,2.41c-6.58,0-12.48-1.41-17.69-4.23-3.34-1.81-6.23-4.16-8.65-7.05,20.82,0,30.6-4.19,35.18-8.23.04-.03.08-.06.12-.09.72-.59,1.35-1.27,1.88-2.04.06-.08.12-.16.17-.24,2.01-2.77,1.87-5,1.87-5l19.73.14Z" />
          <path style={{fill:"white"}} className='animate-[loader_3000ms_linear_infinite_1000ms]' d="M43.42,54.12c-4.58,4.04-14.36,8.23-35.18,8.23-1.39-1.63-2.62-3.43-3.71-5.41C1.51,51.46,0,44.75,0,36.81c0-.52.01-1.03.02-1.54,5.88,12.76,22.4,18.55,29.41,20.48.03.01.06.02.09.02.9.35,1.85.59,2.86.72.07.01.12.02.16.03.07.01.1.02.1.02.76.1,1.54.15,2.36.15,1.79,0,3.4-.22,4.83-.68,1.37-.43,2.57-1.06,3.59-1.89Z" />
          <path style={{fill:"white"}} className='animate-[loader_3000ms_linear_infinite_1500ms]' d="M29.43,55.75C22.42,53.82,5.9,48.03.02,35.27c.19-7.3,1.72-13.52,4.6-18.63,3.08-5.47,7.23-9.61,12.46-12.42,3.53-1.9,7.33-3.16,11.4-3.77-4.12,7.25-8.08,17.98-8.28,32.62v.05c-.12,1.17-.18,2.4-.18,3.69,0,2.84.25,5.38.74,7.61,0,.01,0,.02.01.03.27,1.22.6,2.34,1.02,3.37,1.17,2.95,2.88,5.17,5.12,6.65.78.52,1.62.94,2.52,1.28Z" />
          <path style={{fill:"white"}} className='animate-[loader_3000ms_linear_infinite_2000ms]' d="M62.88,13.97c-18.21-3.89-29.2.75-34.74,4.57-.01.01-.01.02-.02.01-.44.23-.86.48-1.26.77-1.21.85-2.27,1.91-3.14,3.16-.02.02-.04.05-.05.06-.01.02-.02.03-.02.03-.75,1.05-1.38,2.24-1.9,3.57-.79,2.03-1.31,4.34-1.55,6.93.2-14.64,4.16-25.37,8.28-32.62,1.97-.3,4-.45,6.1-.45,4.52,0,8.69.62,12.49,1.85,3.8,1.24,7.14,3.04,10.01,5.41,2.29,1.89,4.22,4.13,5.8,6.71Z" />
          <path style={{fill:"white"}} className='animate-[loader_3000ms_linear_infinite_2500ms]' d="M67.19,27.86h-19.73c-.14-1.64-.51-3.12-1.11-4.45-.59-1.33-1.41-2.48-2.45-3.45-1.03-.96-2.29-1.71-3.76-2.24-1.47-.52-3.14-.78-5-.78-2.67,0-5,.53-7,1.6,5.54-3.82,16.53-8.46,34.74-4.57.4.65.78,1.32,1.13,2.02,1.75,3.46,2.81,7.41,3.18,11.87Z" />
        </g>
      </g>
    </svg>
    </div>
    // <div className="loader"></div> 
  )
}
