import React from 'react'
import Users from './Components/Users/Users'
import Companies from './Components/Companies/Companies'

export default function AdminDashboard() {
  return (
    <>

    </>
  )
}
