import React, { useEffect, useState } from 'react'
import { IconSearch } from '@tabler/icons-react';
import { Link, useLoaderData } from 'react-router-dom';

export default function Users() {

  const [userList, setUserList] = useState([])
  const loaderData =useLoaderData() 

  
  useEffect(() => {
    setUserList(loaderData)
  }, [])


  return (
    <div>
      <span className='text-3xl font-bold tracking-wide pb-6'>Felhasználók</span>

      {userList ?

        <div className="relative sm:rounded-lg">
          <div className="pb-4 ">
            <label htmlFor="table-search" className="sr-only">Search</label>
            <div className="relative mt-1">
              <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <IconSearch />
              </div>
              <input type="text" id="table-search" className="block pt-2 ps-10 text-sm border rounded-lg w-80 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="Felhasználó keresése" />
            </div>
          </div>
          <table className="w-full text-sm text-left rtl:text-right  text-gray-400">
            <thead className="text-xs  uppercase bg-gray-700 text-gray-400">
              <tr>
                <th scope="col" className="p-4  table-hidden-cell">
                  <div className="flex items-center">
                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600  rounded  focus:ring-blue-600 ring-offset-gray-800 focus:ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600" />
                    <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 table-hidden-cell">
                  Név
                </th>
                <th scope="col" className="px-6 py-3 table-hidden-cell">
                  Cég
                </th>
                <th scope="col" className="px-6 py-3 table-hidden-cell">
                  Felhasználónév
                </th>
                <th scope="col" className="px-6 py-3 table-hidden-cell">
                  Telefonszám
                </th>
                <th scope="col" className="px-6 py-3 table-hidden-cell">
                  Uid
                </th>
                <th scope="col" className="px-6 py-3 table-hidden-cell">

                </th>
              </tr>
            </thead>
            <tbody className='flex md:table-row-group flex-wrap flex-shrink'>

              {userList.map((user, index) => {
                return (
                <tr key={index} className="bg-gray-800 border-gray-700 border-b-2 flex flex-col w-full md:table-row hover:bg-gray-600">
                  <td className="w-4 p-4  table-hidden-cell">
                    <div className="flex items-center ">
                      <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600  rounded focus:ring-blue-600 ring-offset-gray-800 focus:ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600" />
                      <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                    </div>
                  </td>
                  <th scope="row" className="px-6 pt-4 md:py-4 flex flex-col">
                    <span className='md:text-base text-xl  font-medium whitespace-nowrap text-white'>{Object.values(user)[0].firstName + " " + Object.values(user)[0].lastName}</span>
                    <span  className=' font-light '>{Object.values(user)[0].email}</span>
                  </th>
                  <td className="px-6 py-4 table-cell">
                    <span>Cég: </span>
                    {Object.values(user)[0].company}
                  </td>
                  <td className="px-6 py-4 table-cell">
                    <span>Felhasználónév: </span>
                    {Object.values(user)[0].username}
                  </td>
                  <td className="px-6 py-4 table-cell">
                    <span>Telefonszám: </span>
                    {Object.values(user)[0].phoneNumber}
                  </td>
                  <td className="px-6 py-4 table-cell" title={Object.keys(user)[0]}>
                    <span>Uid: </span>
                    {Object.keys(user)[0]}
                  </td>
                  <td className="px-6 py-4">
                    <Link to={"/admin/user/edit/" + Object.keys(user)[0]} className="font-medium text-blue-500 hover:underline">Szerkesztés</Link>
                  </td>
                </tr>)
              })}

            </tbody>
          </table>
        </div>

        : null}
    </div>
  )
}