import React, { useEffect } from 'react'
import { useStateContext } from '../../Backend/ContextProvider';
import { InputSelect } from './InputSelect';

export default function DatePicker(props) {

    const { user, setUser } = useStateContext();

    function getYearsNew() {
        var years = []
        const year = new Date().getFullYear();
         for (let i = year; i > (year - 100); i--) {
            years.push(<option onClick={e => {updateBirthday(e)}} key={i} value={i} >{i}</option>)
        }
        return years;
    }
    function getMonths() {
        var months = [
            { "1": "Január" },
            { "2": "Február" },
            { "3": "Március" },
            { "4": "Április" },
            { "5": "Május" },
            { "6": "Június" },
            { "7": "Július" },
            { "8": "Augusztus" },
            { "9": "Szeptember" },
            { "10": "Október" },
            { "11": "November" },
            { "12": "December" },
        ]
        var month = []

        for (let i = 1; i < months.length + 1; i++) {
            if (props.monthName) {
                month.push(<option key={i} onClick={e => {updateBirthday(e)}} value={i} >{Object.values(months[i - 1])[0]}</option>)
                // month.push(<option key={i} selected={user.birthday.month == i ? true : false} value={i}>{Object.values(months[i - 1])[0]}</option>)
            } else {
                month.push(<option key={i} onClick={e => {updateBirthday(e)}} value={i} >{i}</option>)
                // month.push(<option key={i} selected={user.birthday.month == i ? true : false} value={i}>{Object.keys(months[i])[0]}</option>)
            }
        }
        return month;
    }
    
    
    function getDays() {
        var days = []
        
        for (let i = 1; i <= 31; i++) {
            days.push(<option key={i} onClick={e => {updateBirthday(e)}} value={i} >{i}</option>)
            // days.push(<option key={i} selected={user.birthday.day == i ? true : false} value={i}>{i}</option>)
        }
        return days;
    }

    async function updateBirthday(e) {
        const name = e.name;
        await setUser(prevData => ({
            ...prevData,
            "birthday": {
                ...prevData.birthday,
                [name]: e.value
            },
        }));
        await setUser(prevData => ({
            ...prevData,
            "age": _calculateAge(new Date(document.getElementById("year").getAttribute('value') + "/" + document.getElementById("month").getAttribute('value') + "/" + document.getElementById("day").getAttribute('value')))
        }));

    }

    function _calculateAge(birthday) { // birthday is a date
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    

    return (
        <div className={'flex ' + props.className}>
            <InputSelect className={"mr-1"} defaultValue={props.year?props.year:user.birthday.year}  noValue={true} placeholder={"Születési dátum"} cta={"Év"} id="year">
                {getYearsNew()}
            </InputSelect>
            <InputSelect className={"mx-1"} defaultValue={props.month?props.month:user.birthday.month} noValue={true} placeholder={""} cta={"Hónap"} id="month">
                {getMonths()}
            </InputSelect>
            <InputSelect className={"ml-1"} defaultValue={props.day?props.day:user.birthday.day} noValue={true} placeholder={""} cta={"Nap"} id="day">
                {getDays()}
            </InputSelect>
        </div >
    )
}
