import React from 'react'
import { InputSelect } from './InputSelect'

export const EnvLight = ({ clickAction, value }) => {
    return (
        <InputSelect defaultValue={value} placeholder={"Fényviszonyok"} id="env" cta={"Milyen környezetben komfortos a munkavégzés?"}>
            <option onClick={(e) => clickAction(e)} value={0}>Nagyon sötét</option>
            <option onClick={(e) => clickAction(e)} value={1}>Sötét</option>
            <option onClick={(e) => clickAction(e)} value={2}>Természetes</option>
            <option onClick={(e) => clickAction(e)} value={3}>Világos</option>
            <option onClick={(e) => clickAction(e)} value={4}>Nagyon Világos</option>
        </InputSelect>
    )
}

export const FoodAmount = ({ clickAction, value }) => {
    return (
        <InputSelect defaultValue={value} placeholder={"Kaja mennyiség"} id="foodAmount" cta={"Adja meg, hogy átlagosan mennyit eszik?"}>
            <option onClick={(e) => clickAction(e)} value={0}>Nagyon keveset</option>
            <option onClick={(e) => clickAction(e)} value={1}>Keveset</option>
            <option onClick={(e) => clickAction(e)} value={2}>Átlagos adagot</option>
            <option onClick={(e) => clickAction(e)} value={3}>Sokat</option>
            <option onClick={(e) => clickAction(e)} value={4}>Nagyon sokat</option>
        </InputSelect>
    )
}
export const FrontSens = ({ clickAction, value }) => {
    return (

        <InputSelect defaultValue={value} placeholder={"Frontérzékenység"} id="frontSens" cta={"Adja meg mennyire frontérzákeny?"}>
            <option onClick={(e) => clickAction(e)} value={0}>Egyáltalán nem vagyok frontérzákeny</option>
            <option onClick={(e) => clickAction(e)} value={1}>A nagyobb változásokat megérzem</option>
            <option onClick={(e) => clickAction(e)} value={2}>Elég egy kisebb frontváltozás is hogy megérezzem</option>
            <option onClick={(e) => clickAction(e)} value={3}>A migrén lassan a szobatársam</option>
        </InputSelect>
    )
}
export const Sweat = ({ clickAction, value }) => {
    return (
        <InputSelect defaultValue={value} placeholder={"Izzadékonyság"} id="sweat" cta={"Adja meg, hogy mennyire izzadós"}>
            <option onClick={(e) => clickAction(e)} value={0}>Egyáltalán nem vagyok izzadós</option>
            <option onClick={(e) => clickAction(e)} value={1}>Nagyobb mozgás enyhén megizzaszt</option>
            <option onClick={(e) => clickAction(e)} value={2}>Egy nyári napon is megizzadok</option>
            <option onClick={(e) => clickAction(e)} value={3}>Kisebb mozgást is megérzek</option>
            <option onClick={(e) => clickAction(e)} value={4}>Mindig izzadok</option>
        </InputSelect>
    )
}
export const BodyShape = ({ clickAction, value }) => {
    return (
        <InputSelect defaultValue={value} placeholder={"Testalkat"} id="bodyShape" cta={"Válassza ki a testalkatát"}>
            <option onClick={(e) => clickAction(e)}  value={0}>Kórosan Sovány</option>
            <option onClick={(e) => clickAction(e)}  value={1}>Tulzottan Sovány</option>
            <option onClick={(e) => clickAction(e)}  value={2}>Sovány</option>
            <option onClick={(e) => clickAction(e)}  value={3}>Normális</option>
            <option onClick={(e) => clickAction(e)}  value={4}>Van egy kis felesleg</option>
            <option onClick={(e) => clickAction(e)}  value={5}>Túlsúlyos</option>
            <option onClick={(e) => clickAction(e)}  value={6}>Kórosan tulsúlyos</option>
        </InputSelect>
    )
}


export const Gender = ({ clickAction, value }) => {
    return (
        <InputSelect defaultValue={value} placeholder={"Nem"} id="gender" cta={"Válassza ki a nemét"}>
            <option onClick={(e) => clickAction(e)} value={0}>Nő</option>
            <option onClick={(e) => clickAction(e)} value={1}>Férfi</option>
            <option onClick={(e) => clickAction(e)} value={2}>Egyéb</option>
        </InputSelect>
    )
}



