import React, { useEffect, useState } from 'react'
import { CustomModal } from '../../../../utils/Components/Modal'
import { InputText } from '../../../../utils/Components/InputText'
import { geocode, stringToHash } from '../../../../utils/Getters'
import { IconCurrentLocation, IconReload, IconSearch } from '@tabler/icons-react'
import { Map } from '../../../../utils/Components/Map/Map'
import Loader from '../../../../utils/Components/Loader'
import { pushData } from '../../../../Backend/Firestore'

export default function CreateCompany(props) {
    const [position, _setPosition] = useState(null)
    const [map, setMap] = useState(null)
    const [address, setAddress] = useState("")
    const [userPassword, setUserPassword] = useState("")
    const [adminPassword, setAdminPassword] = useState("")
    const [newCompanyId, setCompanyId] = useState(0)
    const [addressList, setAddressList] = useState(null)
    const [newCompany, setNewCompany] = useState({
        name: '',
        location: {
            coordinates: {
                latitude: 47.1625,
                longitude: 19.5033,
            }
        },
        users: {}
    })

    async function setPosition(e, index) {

        if (typeof index === "number") {
            const address = addressList[index]
            await setNewCompany(company => ({
                ...company,
                location: {
                    coordinates: {
                        latitude: e.lat,
                        longitude: e.lng,
                    },
                    county: address.components.county || null,
                    city: address.components.city || null,
                    country: address.components.country || null,
                    district: address.components.borough || null,
                    suburb: address.components.suburb || null,
                    house_number: address.components.house_number || null,
                    street: address.components.road || null,
                    zipCode: address.components.postcode || null,
                }
            }))
            setAddress(address.formatted)
        } else if (index === "located") {
            geocode(e.lat + "," + e.lng).then(async (res) => {
                const addr = res[0]
                await setNewCompany(company => ({
                    ...company,
                    location: {
                        coordinates: {
                            latitude: e.lat,
                            longitude: e.lng,
                        },
                        county: addr.components.county || null,
                        city: addr.components.city || null,
                        country: addr.components.country || null,
                        district: addr.components.borough || null,
                        suburb: addr.components.suburb || null,
                        house_number: addr.components.house_number || null,
                        street: addr.components.road || null,
                        zipCode: addr.components.postcode || null,

                    }
                }))
                await setAddress(addr.formatted)
            })
        }
        await _setPosition(e)
    }



    async function onCompanyChange(e) {
        const varName = e.target.id
        setNewCompany(company => ({
            ...company,
            [varName]: e.target.value
        }))
    }
    async function onAddressChange(e) {
        const varName = e.target.id
        setNewCompany(company => ({
            ...company,
            location: {
                ...company.location,
                [varName]: e.target.value
            }
        }))
    }

    function setPasswords() {
        setUserPassword(stringToHash(newCompany.name, "user"))
        setAdminPassword(stringToHash(newCompany.name, "admin"))

    }


    async function genNewId() {
        let usedIDs = []
        let newId = 0
        props.companies.forEach(company => {
            usedIDs.push(parseInt(Object.keys(company)[0]))
        })
        function genId() {
            do {
                newId = Math.floor(Math.random() * (99999999 - 10000000) + 10000000)
            } while (usedIDs.includes(newId) || newId === 0)
            return newId
        }

        await setCompanyId(await parseInt(genId()))
    }



    function onAddressSearch() {
        setAddressList("loading")
        const delayDebounceFn = setTimeout(() => {
            geocode(address).then((result) => {
                setAddressList(result)
            })
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }

    useEffect(() => {
        genNewId();
    }, [])



    return (
        <CustomModal
            showModal={props.showModal}
            setShowModal={() => props.setShowModal(false)}
            title="Új cég létrehozása"
            doneText="Létrehozás"
            doneAction={() => {
                pushData("companies", "/" + newCompanyId, newCompany);
                props.setShowModal(false)
                setAddress("")
                setUserPassword("")
                setAdminPassword("")
                setCompanyId(0)
                setAddressList(null)
                setNewCompany({
                    name: '',
                    location: {
                        coordinates: {
                            latitude: 47.1625,
                            longitude: 19.5033,
                        }
                    },
                    users: {}
                })
            }

            }
        >
            <div className='flex gap-2 flex-row'>
                <InputText id={"name"}
                    required
                    onChange={async (e) => {
                        await setNewCompany(company => ({
                            ...company,
                            "name": e.target.value
                        }))
                    }} onBlur={() => setPasswords()} placeholder="Cég neve" className={"!mb-0 "}></InputText>
                <div className='flex gap-2 align-middle'>
                    <span className='text-nowrap items-center flex justify-center'>Id: {newCompanyId}</span>
                    {/* <InputText id={"id"} disabled onChange={(e) => onCompanyChange(e)} type="number" placeholder="Azonosító" className={"!mb-0"} >{newCompanyId}</InputText> */}
                    <button type='text' className='' onClick={() => genNewId()}><IconReload /></button>
                </div>
            </div>
            <div className='flex gap-2 flex-col sm:flex-row'>
                <InputText disabled placeholder="User jelszó">{userPassword}</InputText>
                <InputText disabled placeholder="Admin jelszó">{adminPassword}</InputText>
            </div>

            <div className='flex gap-2 flex-col'>
                <div className='flex gap-2 sm:flex-row flex-col'>
                    <InputText id={"address"} onChange={(e) => setAddress(e.target.value)} placeholder="Keresés" className={"!mb-0"}>{newCompany.address}</InputText>
                    <div className='flex gap-2'>
                        <button type='text' onClick={() => onAddressSearch()} ><IconSearch /></button>
                        <button type='text' onClick={() => map.locate()}><IconCurrentLocation /></button>
                    </div>
                </div>
                {!addressList ? null :
                    <div className='flex flex-col gap-1'>
                        {addressList === "loading" ?
                            <span className='flex justify-center'>
                                <Loader className="mx-auto !w-12" />
                            </span>
                            :

                            addressList.map((address, index) => {
                                return (
                                    <>

                                        <span key={address.formatted}
                                            onClick={async () => {
                                                await setPosition(address.geometry, index)
                                                await map.flyTo(address.geometry, 18)
                                                await setAddress(address.formatted)
                                                await setAddressList(null)
                                            }}
                                            className=' truncate hover:overflow-visible hover:whitespace-normal hover:line-clamp-4 p-2 bg-slate-800 bg-opacity-80 hover:bg-opacity-50 !transition-all rounded-md'
                                            title={address.formatted}>
                                            {address.formatted}
                                        </span></>)
                            })}
                    </div>
                }
                <div className='max-h-[250px] min-h-[200px] w-full overflow-hidden rounded-lg'>
                    <Map pos={position} map={map} setMap={setMap} setPosition={setPosition} >
                    </Map>
                </div>
            </div>
            <div className='flex gap-2'>
                <InputText id={"country"} onChange={(e) => { onAddressChange(e) }} placeholder="Ország">{newCompany.location.country}</InputText>
                <InputText id={"county"} onChange={(e) => { onAddressChange(e) }} placeholder="Megye">{newCompany.location.county}</InputText>
            </div>
            <div className='flex gap-2'>
                <InputText required id={"zipCode"} onChange={(e) => { onAddressChange(e) }} placeholder="Irányítószám">{newCompany.location.zipCode}</InputText>
                <InputText required id={"city"} onChange={(e) => { onAddressChange(e) }} placeholder="Város">{newCompany.location.city}</InputText>
            </div>
            <div className='flex gap-2'>
                <InputText id={"district"} onChange={(e) => { onAddressChange(e) }} placeholder="Kerület">{newCompany.location.district}</InputText>
                <InputText id={"suburb"} onChange={(e) => { onAddressChange(e) }} placeholder="Városrész">{newCompany.location.suburb}</InputText>
            </div>
            <div className='flex gap-2 flex-col sm:flex-row '>
                <InputText required id={"street"} onChange={(e) => { onAddressChange(e) }} className={"!sm:w-1/2 w-full"} placeholder="Utca">{newCompany.location.street}</InputText>
                <div className='flex gap-2 !sm:w-1/2 w-full'>
                    <InputText required id={"house_number"} onChange={(e) => { onAddressChange(e) }} className={"!w-1/3"} placeholder="Házszám">{newCompany.location.house_number}</InputText>
                    <InputText id={"floor"} onChange={(e) => { onAddressChange(e) }} className={"!w-1/3"} placeholder="Szint">{newCompany.location.floor}</InputText>
                    <InputText id={"door"} onChange={(e) => { onAddressChange(e) }} className={"!w-1/3"} placeholder="Ajtó">{newCompany.location.door}</InputText>
                </div>
            </div>


        </CustomModal>
    )
}
