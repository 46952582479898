import React from 'react'
import { useLoaderData, useParams } from 'react-router-dom'
import { InputText } from '../../../../utils/Components/InputText'
import DatePicker from '../../../../utils/Components/DatePicker'
import { InputSelect } from '../../../../utils/Components/InputSelect'
import { EnvLight, FoodAmount, FrontSens, Gender, Sweat } from '../../../../utils/Components/HealthDropdowns'

export default function EditUser() {
  const user = useLoaderData()

  return (

    <>
      <div>
        <div className='flex flex-col items-center pb-4' >
          <img src={user.avatarUrl} className='max-w-[250px] rounded-md' />
          <div className=' font-light text-sm'>uid: {useParams().userId}</div>
        </div>
        <div className='flex gap-2'>
          <InputText placeholder={"Vezetéknév"}>{user.firstName}</InputText>
          <InputText placeholder={"Keresztnév"}>{user.lastName}</InputText>
        </div>
        <InputText placeholder={"Felhasználónév"}>{user.username}</InputText>
        <InputText placeholder={"Email"}>{user.email}</InputText>
        <InputText placeholder={"Telefonszám"}>{user.phoneNumber}</InputText>
        <DatePicker monthName
          year={user.birthday.year}
          month={user.birthday.month}
          day={user.birthday.day}></DatePicker>
      </div>
      <div className='flex flex-col items-start'>
        <div className='flex w-full gap-2'>
          <InputText placeholder={"Cég"}>{user.company}</InputText>
          <InputText placeholder={"Cég jogosultság"}>{user.role}</InputText>
        </div>

        <div className='flex w-full gap-2'>
          <InputText placeholder={"Magasság (cm)"}>{user.height}</InputText>
          <InputText placeholder={"Súly (kg)"}>{user.weight}</InputText>
        </div>
        <EnvLight clickAction={e => console.log(e)} value={user.env}></EnvLight>
        <FoodAmount clickAction={e => console.log(e)} value={user.foodAmount}></FoodAmount>
        <FrontSens clickAction={e => console.log(e)} value={user.frontSens}></FrontSens>
        <Sweat clickAction={e => console.log(e)} value={user.sweat}></Sweat>
        <Gender clickAction={e => console.log(e)} value={user.gender}></Gender>
      </div>
    </>

  )
}
