import React, { useEffect } from 'react'
import Header from '../Header.jsx'
import Footer from '../Footer.jsx'
import { useStateContext } from '../../Backend/ContextProvider.jsx'
import { Navigate, Outlet } from 'react-router-dom'
import Loader from '../../utils/Components/Loader.jsx'
export default function DefaultLayout() {

    const { user, userAuth } = useStateContext()


    if (!userAuth) {
        return <Navigate to="/login" />
    }


    return (
        <div className='flex flex-col flex-nowrap h-screen content-stretch items-stretch bg-slate-900'>
            <Header />
            <div className='text-white flex flex-col flex-grow justify-center overflow-y-auto overflow-x-hidden items-center top-0 left-0'>
                <Outlet />
            </div>
            <Footer />
        </div>

    )
}
