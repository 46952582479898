import BigLogo from "../../assets/LogoBig.svg"
import SmallLogo from "../../assets/LogoSmall.svg"
export default function Logo({className}) {
  return (
    <img alt="Logo" className={className?className:"w-1/3"} src={BigLogo}/>
  )
}
export function LogoSmall() {
  return (
    <img alt="Logo" className="w-1/3" src={SmallLogo}/>
  )
}
