import { addDoc, setDoc, collection, updateDoc, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, orderBy, query, where, onSnapshot } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { FirebaseConfig } from "./firebaseConfig";
import { toast } from "react-toastify";
import { getAuthErrors } from '../utils/AuthErrors';

const firebaseApp = initializeApp(FirebaseConfig)
const db = getFirestore(firebaseApp);

/**
 * Dokumentum értékét adja vissza
 *  pl.: Egy adott felhasználó értékeit 
 * @param col - Melyik kollekcióban keressük.
 * @param path - Melyik azonosítót keressük
 * @returns Promise az értékekkel 
 */
export async function getData(col, path) {
    const db = await getFirestore(initializeApp(FirebaseConfig))
    const docRef = await doc(db, col, path);
    const docSnap = await getDoc(docRef);

    return docSnap.data();
    
}

/**
 * A Kollekció tartalmát adja vissza 
 *  pl.: A felhasználók collection-ből minden felhasználót lekérdez
 * @param col - Melyik kollekcióban keressük.
 * @returns Promise az értékekkel 
 */
export async function getCollectionData(col) {
    const db = await getFirestore(initializeApp(FirebaseConfig))
    const docSnap = await getDocs(collection(db, col));
    let datas = []
    docSnap.forEach((data) => {
      datas.push({ [data.id]: data.data() })
    })
    return datas
  }

/**
 * növekvő/csökkenő sorrendben adja vissza a kollekcióban található értékeket
 *  pl.: A feedback collection-ből a key értékeket keresi meg minden
 * feedback-ben és az "order" által megadott módon adja vissza
 * a "limitNumber" mennyiségű adatot
 * @param col - Melyik kollekcióban keressük.
 * @param key - melyik változót keressük
 * @param order - Milyen listázási módot szeretnénk (asc/desc).
 * @param limitNumber - Hány értéket adjon vissza.
 * @returns Promise az értékekkel 
 */
export async function getLastData(col, key, order, limitNumber) {

    const db = await getFirestore(initializeApp(FirebaseConfig))
    const docRef = await collection(db, col);
    const q = query(docRef, orderBy(key, order), limit(limitNumber));
    return await getDocs(q).then(snapshot => {
        return (snapshot.docs[0].data());
    })
}

/**
 * Ellenörzi, hogy a dokumentumban az adott változó név és érték létezik
 *  csakis egyedi kulcs-értéknél használva, amiből nem lehet ugyanolyan érték, pl email-cím vagy felhasználónév
 * @param col - Melyik kollekcióban keresünk.
 * @param fieldName - Az adat változó neve 
 * @param checkVar - A változó értéke
 * 
 * @returns String - az adat dokumentum azonosítója
 */
export async function getContainingDocumentName(col, fieldName, searchVal) {
    const db = await getFirestore(initializeApp(FirebaseConfig))
    const docRef = await collection(db, col);
    const q = query(docRef, where(fieldName,"==", searchVal), limit(1));
    return await getDocs(q).then(async (snapshot) => {
        return (snapshot.docs[0].ref._key.path.segments[(snapshot.docs[0].ref._key.path.segments).length-1])
    })
}

/**
 * Ellenörzi, hogy a dokumentumban az adott változó név és érték létezik
 *
 * @param col - Melyik kollekcióban keresünk.
 * @param fieldName - Az adat változó neve 
 * @param checkVar - A változó értéke
 * 
 * @returns Promise, ami a megtalált értékeket adja vissza egy Listában
 */
export async function checkExists(col, fieldName, checkVar) {
    const db = await getFirestore(initializeApp(FirebaseConfig))
    const docRef = await collection(db, col);
    // const docSnap = await query(docRef, orderBy(orderName,order) ,limit(limitNumber));
    const q = await query(docRef, where(fieldName, "==", checkVar));
    let queryData = [];
    (await getDocs(q)).forEach((data) => {
        queryData.push({[data.id]:data.data()})
    })
    return queryData
}

/**
 * Új dokumentumot hoz létre a kollekcióban egy egyedi azonosítóval
 *
 * @param col - Melyik kollekcióba írjuk az új dokumentumot.
 * @param data - Az adat 
 */
export async function createDoc(col, data) {
    const db = await getFirestore(initializeApp(FirebaseConfig))
    const colRef = await collection(db, col);
    return await addDoc(colRef, data)
}

/**
 * Dokumentumot frissít az adatokkal, hogyha nem létezik ilyen dokumentum, akkor létrehozza azt
 * pl.: Ha egy új céget akarunk létrehozni, egy adott(új) azonosítóval
 * pl.: egy meglévő Céget akarunk frissíteni 
 *
 * @param col - Melyik kollekcióba írjuk az új dokumentumot.
 * @param docu - Melyik Dokumentumot szeretnéd firssíteni/létrehozni
 * @param data - Az adat 
 */
export async function pushData(col, docu, data) {
    const db = await getFirestore(initializeApp(FirebaseConfig))
    await setDoc(doc(db, col, docu), data)
        .catch(error => toast.warning(getAuthErrors(error.code)));
}

/**
 * Dokumentum adatait frissíti 
 *
 * @param col - Melyik kollekcióban akarunk módosítást.
 * @param doc - Melyik Dokumentumot szeretnénk modosítani
 * @param data - Az adat amit frissíteni szerentél
 * @param doneMessage - Ha a törlés sikeres, akkor ez az üzenet fog megjelenni {@link(toast)}
 */
export async function updateData(col, doc, data, doneMessage) {
    await updateDoc(doc(db, col, doc), data)
        .then(() => toast.success(doneMessage))
        .catch(error => toast.warning(getAuthErrors(error.code)));
}

/**
 * Dokumentumot töröl az adott kollekcióban
 *
 * @param col - Melyik kollekcióban keressük.
 * @param doc - Melyik Dokumentumot szeretnénk törölni
 * @param doneMessage - Ha a törlés sikeres, akkor ez az üzenet fog megjelenni {@link(toast)}
 */
export async function deleteData(col, doc, doneMessage) {
    await deleteDoc(doc(db, col, doc))
        .then(() => toast.success(doneMessage))
        .catch(error => toast.warning(getAuthErrors(error.code)));
}


/**
 * Érték keresés változónév alapján
 *
 * @param col - Melyik kollekcióban keressük.
 * @param fieldName - Milyen változónevet szeretnék ellenőrizni.
 * @param value - Az érték amit ellenőrizni szeretnénk.
 * @returns boolan értéket ad vissza, TRUE ha van már ilyen érték, a keresett helyen, FALSE ha nincs találat 
 */
export async function checkUsed(col, fieldName, value) {
    return checkExists(col, fieldName, value).then((snapshot) => {
        if (snapshot.length >= 1) {
            return true
        } else {
            return false
        }
    }).catch((e) => { 
        return false })

}