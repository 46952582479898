import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect } from 'react'
import { FirebaseConfig } from '../../Backend/firebaseConfig';
import { useStateContext } from '../../Backend/ContextProvider';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getContainingDocumentName, getData } from '../../Backend/Firestore';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import color from '../../utils/colorGenerator';
import { getAuthErrors } from '../../utils/AuthErrors';

export default function BackEndHeader() {

    const firebaseApp = initializeApp(FirebaseConfig)
    const db = getFirestore(firebaseApp);
    const auth = getAuth(initializeApp(FirebaseConfig))


    const { user, setUser, userAuth, setUserAuth } = useStateContext()
    useEffect(() => {
        // initFlowbite();
        const unsubscribe = onAuthStateChanged(auth, async (currentuser) => {
            await setUserAuth(currentuser);
            //Belépés, beállítja a StateContext-be a user-t hogy globálisan elérhető legyen
            if (currentuser !== null || (currentuser !== null && Object.keys(user).length === 0 && currentuser.uid !== undefined)) {
                await getData("users", "/" + currentuser.uid).then(async data => {
                    if (data) {
                        await setUser(data)
                    }
                })
            } else {
                setUser({})
            }
            // Regisztráció, csak akkor fut le, hogyha a user created date megegyezik a last login-nal
            if (((userAuth.metadata.createdAt || false) === (userAuth.metadata.lastLoginAt || false)) && Object.keys(user).length !== 0) {
                try {
                    //get the Company id
                    const companyId = await getContainingDocumentName("companies", "name", user.company);
                    // Add the current user to the users
                    await setDoc(doc(db, "users", currentuser.uid), {
                        'birthday': user.birthday || null,
                        'age': user.age || null,
                        'avatarUrl': `https://ui-avatars.com/api/?name=${user.firstName} ${user.lastName}&size=512&${color()}` || null,
                        'bodyShape': user.bodyShape || null,
                        'phoneNumber': user.phoneNumber || null,
                        'company': companyId || null,
                        'email': currentuser.email || null,
                        'env': user.env || null,
                        'firstName': user.firstName || null,
                        'foodAmount': user.foodAmount || null,
                        'frontSens': user.frontSens || null,
                        'height': user.height || null,
                        'lastName': user.lastName || null,
                        'gender': user.gender || null,
                        'role': user.role || null,
                        'sweat': user.sweat || null,
                        'username': user.username || null,
                        'weight': user.weight || null,
                    });
                    // Add the current user to the companies account
                    await setDoc(doc(db, "companies", companyId), {
                        users: { [currentuser.uid]: user.role }
                    }, { merge: true })
                } catch (err) { toast.warning("Hiba a regisztráció közbena:" + getAuthErrors(err)) }
            } else { return }


        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <>
            <ToastContainer position="top-center" autoClose={5000} theme="dark" transition={Zoom} />
        </>
    )
}
