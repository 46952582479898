import React, { useState } from 'react'
import { useStateContext } from '../../Backend/ContextProvider'

import Loader from '../../utils/Components/Loader'
import Company from './Company'
import { IconBriefcase, IconHealthRecognition, IconUser } from '@tabler/icons-react'
import Personal from './Personal'
import Avatar from './Avatar'
import Health from './Health'
import { updateData } from '../../Backend/Firestore'
import { reAuth, updateUserAuth } from '../../Backend/FirebaseAuth'
import { toast } from 'react-toastify'
import { getAuthErrors } from '../../utils/AuthErrors'
import { CustomModal } from '../../utils/Components/Modal'
import { InputText } from '../../utils/Components/InputText'
import { DeleteLoggedUser } from '../../utils/databaseActions'

export default function User() {
  const [showModal, setShowModal] = useState(false)
  const [authPassword, setAuthPassword] = useState("")
  const [activePanel, setActivePanel] = useState(0)
  const { refresher, setRefresher, user, setUser, userAuth } = useStateContext()

  async function updateUserDropdown(e) {
    const name = e.name;
    await setUser(prevData => ({
      ...prevData,
      [name]: e.value
    }));
  }
  async function updateUserText(e) {
    const name = e.target.id;
    await setUser(prevData => ({
      ...prevData,
      [name]: e.target.value
    }));
  }

  async function saveUserChanges() {
    await updateUserAuth(
      {
        displayName: user.firstName + " " + user.lastName,
        email: user.email,
        phoneNumber: parseInt(user.phoneNumber),
        photoURL: user.avatarUrl
      }
    ).then(async () => {
      await updateData("users", "/" + userAuth.uid, user);
    })
    await setRefresher(!refresher)
  }




  async function onDeleteUser() {
    reAuth(userAuth.email, authPassword).then(async e => {
      DeleteLoggedUser(userAuth, setUser, user)
    }).catch((error) => {
      toast.error("Felhasználó törlése közben hiba lépett fel<br/>" + getAuthErrors(error))
    });
  }


  return (
    <>
      {true ?
        <div className='flex flex-col justify-center h-full w-full p-5
        max-w-md
        '>
          <Avatar {...user} userAuth={{ ...userAuth }} updateUserText={(w) => updateUserText(w)} />
          <div className="w-full flex content-stretch mb-4" role="group">
            <button onClick={() => setActivePanel(1)} type="button" className=" flex p-0 py-2 justify-center items-center grow mr-1 bg-emerald-500 hover:bg-emerald-400">
              <IconUser className='sm:hidden' />
              <span className='hidden sm:block'>Személyes</span>
            </button>
            <button onClick={() => setActivePanel(2)} type="button" className=" flex p-0 py-2 justify-center items-center grow mx-1 bg-blue-500 hover:bg-blue-400">
              <IconBriefcase className='sm:hidden' />
              <span className='hidden sm:block'>Munkahelyi</span>
            </button>
            <button onClick={() => setActivePanel(3)} type="button" className=" flex p-0 py-2 justify-center items-center grow ml-1 bg-rose-500 hover:bg-rose-400">
              <IconHealthRecognition className='sm:hidden' />
              <span className='hidden sm:block'>Egészségügyi</span>
            </button>
          </div>
          {activePanel === 1 ? <Personal {...user} updateUserDropdown={(w) => updateUserDropdown(w)} updateUserText={(w) => updateUserText(w)} userAuth={{ ...userAuth }} /> : null}
          {activePanel === 2 ? <Company {...user} updateUserText={(w) => updateUserText(w)} userAuth={{ ...userAuth }} /> : null}
          {activePanel === 3 ? <Health {...user} updateUserDropdown={(w) => updateUserDropdown(w)} updateUserText={(w) => updateUserText(w)} /> : null}
          <div className="flex flex-row">


            <button className='warning mr-1 w-1/2' onClick={() => setShowModal(true)}>Delete</button>
            <button className='success ml-1 w-1/2' onClick={e => { saveUserChanges() }}>Save</button>
          </div>




          <CustomModal
            showModal={showModal} setShowModal={() => setShowModal()}
            title={`${user.username} nevű felhasználó törlése`}
            doneAction={() => onDeleteUser()}
          >
            A törléshez véglegesítéséhez kérlek add meg a jelszavad:
            <br />
            <InputText onChange={(e) => setAuthPassword(e.target.value)} placeholder="A törléshez add meg mégegyszer a jelszavad" />

          </CustomModal>
          <div className=''>Cellsius 0.0.5</div>
        </div>
        : <Loader />}
    </>
  )
}
