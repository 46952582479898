import { IconArrowRight, IconBriefcase2, IconDashboard, IconLanguage, IconMenu2, IconMessage2Check, IconUserCheck } from '@tabler/icons-react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../utils/Components/Logo'

export default function Sidebar() {

const [showNav, setShowNav] = useState(false)

   const links = [
      {
         "name": "Visszajelzések",
         "link": "/feedbacks",
         "icon": <IconMessage2Check />
      },
      {
         "name": "Cégek",
         "link": "/admin/companies",
         "icon": <IconBriefcase2 />
      },
      {
         "name": "Felhasználók",
         "link": "/admin/users",
         "icon": <IconUserCheck />
      },
      {
         "name": "Fordítások",
         "link": "/admin/translations",
         "icon": <IconLanguage/>
      },
   ]


   return (
      <>
         <aside className={(!showNav?"-translate-x-full":"") + " fixed h-screen top-0 left-0 z-40 max-w-xs transition-transform xl:relative xl:translate-x-0"}>
         <button onClick={()=>setShowNav(!showNav)} type="button" className={(!showNav?"!translate-x-3/4 hover:pl-3":"") + "  transition-[1000ms] absolute top-1/2 right-0 translate-x-1/2 -translate-y-1/2 inline-flex items-center p-2 mt-2 ms-3 text-sm rounded-lg xl:hidden hover:bg-gray-700 focus:ring-gray-600"}>
            <IconArrowRight className={(showNav?" rotate-180":"") + "  transition-all"}/>
         </button>
            <div className="h-full px-3 py-4 overflow-y-auto  bg-gray-800">
               <div className='w-full flex justify-center'><Logo className={"w-1/2 py-4"}/></div>
               <ul className="space-y-2 font-medium">
                        <li>
                           <Link to={"/"} onClick={()=>setShowNav(!showNav)} className="flex items-center p-2 rounded-lg text-white hover:bg-gray-700 group">
                              <IconDashboard/>
                              <span className="ms-3">Főoldal</span>
                           </Link>
                        </li>
                        <hr className='border-slate-400 w-3/4 mx-auto'/>
                  {links.map((link, index) => {
                     return (
                        <li key={index}>
                           <Link to={link.link} onClick={()=>setShowNav(!showNav)} className="flex items-center p-2 rounded-lg text-white hover:bg-gray-700 group">
                              {link.icon}
                              <span className="ms-3">{link.name}</span>
                           </Link>
                        </li>)
                  })}

               </ul>
            </div>
         </aside>
      </>


   )
}
