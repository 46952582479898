import { IconX } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import { Form } from 'react-router-dom'

export const CustomModal = ({showModal,doneAction, setShowModal, title, children, doneText, doneType,cancelText})=> {


    return (<>
        <Form  onSubmit={(e)=>{e.preventDefault()}} className={(showModal?"":"hidden")+ " bg-black/50 fixed flex overflow-y-auto overflow-x-hidden  top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"}>
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative  rounded-lg shadow bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-white">
                            {title}
                        </h3>
                        
                    </div>
                    <div className="p-4 md:p-5 space-y-4">
                        {children}
                    </div>
                    <div className="flex items-center justify-end gap-2 p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button onClick={()=>setShowModal(false)} type="button" className="secondary">{cancelText?cancelText:"Mégsem"}</button>
                        <button type="submit" onClick={()=>doneAction} className={doneType?doneType:"success"}>{doneText}</button>
                    </div>
                </div>
            </div>
        </Form>
    </>
    )
}
