import React from 'react'
import { IconChartArea, IconDashboard, IconMessagePlus, IconUser } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useStateContext } from '../Backend/ContextProvider';

export default function Footer() {
    const { user } = useStateContext()
    return (
        <div className='flex flex-nowrap justify-around z-10 h-16 w-full items-center border-t-2 border-opacity-10 bg-slate-800 border-white'>
            <Link to='/dashboard' className='flex text-white h-full justify-center w-full hover:text-white hover:no-underline hover:bg-slate-700 transition-[1s] flex-col items-center'><IconDashboard size={30} color="white" /><span className='text-xs' >Termosztát</span></Link>
            <Link to='/feedback' className='flex text-white h-full justify-center w-full hover:text-white hover:no-underline hover:bg-slate-700 transition-[1s] flex-col items-center'><IconMessagePlus size={30} color="white" /><span className='text-xs' >Visszajelzés</span></Link>
            <Link to='/user' className='flex text-white h-full justify-center w-full hover:text-white hover:no-underline hover:bg-slate-700 transition-[1s] flex-col items-center'><IconUser size={30} color="white" /><span className='text-xs' >Profilom</span></Link>
            {
                user.role == 'dev' ?
                    <Link to='/admin' className='flex text-white h-full justify-center w-full hover:text-white hover:no-underline hover:bg-slate-700 transition-[1s] flex-col items-center'><IconChartArea size={30} color="white" /><span className='text-xs' >AdminPanel</span></Link>
                    :
                    null
            }
        </div>
    )
}
