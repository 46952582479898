import React, { useEffect, useState } from 'react'
import { InputText } from "../../../utils/Components/InputText"
import { checkUsed } from '../../../Backend/Firestore'
import { stringToHash } from '../../../utils/Getters'
import { useStateContext } from '../../../Backend/ContextProvider'
export default function CompanyInfo(props) {

    const [companyFound, setCompanyFound] = useState(false)
    const { setUser, user } = useStateContext()

    useEffect(() => {
        setCompanyFound(checkUsed("companies", "name", props.user.company))
    }, [])

    async function testCompany() {
        await setCompanyFound(await checkUsed("companies", "name", props.user.company))
    }

    async function setRole() {
        const role = (stringToHash(props.user.company, "admin") == props.user.companyPassword) ? "admin" : "user"


        setUser(prevData => ({
            ...prevData,
            ["role"]: role
        }))


    }


    return (
        <>
            <InputText onBlur={() => testCompany()} onChange={e => props.updateUserData(e)} placeholder="Cég neve" id={"company"} >{props.user.company}</InputText>
            <InputText onChange={e => { props.updateUserData(e);}} placeholder="Cég által megadott jelszó" id={"companyPassword"} >{props.user.companyPassword}</InputText>
            <div className='flex'>
                <button type="buttom"
                    onClick={(e) => props.setRegStep(props.regStep - 1)}
                    className={"w-1/2 mr-1 "}>
                    Vissza
                </button>
                <button type="submit"
                    disabled={!(props.user.company && props.user.companyPassword && companyFound &&
                        ((stringToHash(props.user.company, "user") == props.user.companyPassword)
                            || (stringToHash(props.user.company, "admin") == props.user.companyPassword)))
                    }
                    onClick={() => { props.setRegStep(props.regStep + 1); setRole() }}
                    className={"w-1/2 ml-1 "}>
                    Tovább
                </button>
            </div>
        </>
    )
}
