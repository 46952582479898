import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../Backend/ContextProvider'
import { createDoc } from '../../Backend/Firestore';
import Loader from '../../utils/Components/Loader';
import { toast } from 'react-toastify';
import { Link, useLoaderData } from 'react-router-dom';
import { InputSelect } from '../../utils/Components/InputSelect';

export default function Feedback() {

  const { userAuth } = useStateContext()

  const [moods, setMoods] = useState({})
  const [feedback, setFeedback] = useState({
    changed: null,
    feelText: null,
    feelSlider: null,
    moods: {},
    user: userAuth.uid,
  })

  const loaderData = useLoaderData();


  async function onSubmitFeedback(e) {
    createDoc("feedback", feedback, userAuth.uid).then(data => {
      toast.success("Thank you for your feedback!")
    })
  }

  async function updateFeedbackDropdown(e) {
    const name = e.name;
    await setFeedback(prevData => ({
      ...prevData,
      [name]: e.value
    }));
  }

  function updateFeedbackData(e) {
    const name = e.target.name;
    const data = {};
    data[name] = e.target.value
    setFeedback(feedback => ({
      ...feedback,
      ...data
    }));
  }

  useEffect(() => {

    setMoods(loaderData)

  }, [])

  function onMoodsChange(e) {
    const moodName = e.target.id

    if (e.target.checked) {
      setFeedback(feedback => ({
        ...feedback,
        "moods": {
          ...feedback.moods,
          [moodName]: e.target.value
        }
      }))
    } else {
      const { [moodName]: _, ...restObject } = feedback.moods
      setFeedback(feedback => ({
        ...feedback,
        "moods": restObject
      }))

    }
  }

  async function postFeedback(e) {
    if (e.target.disabled) {
      toast.warning("Kérlek minden elemet tölts ki")
    } else {
      await setFeedback(feedback => ({
        ...feedback,
        "timestamp": new Date().getTime(),}))
      await onSubmitFeedback(e)
    }
  }

  return (
    <div className='text-center h-full  md:w-1/2 lg:w-1/3 w-screen'>
      <h1 className='text-5xl font-bold mt-10 mb-5'>
        Feedback
      </h1>
      <div className="flex flex-col p-3">
        <InputSelect id="changed" defaultValue={feedback.changed}  cta="Állítottad a hőmérsékletet?">
          <option onClick={e => updateFeedbackDropdown(e)} value="noChange">Nem állítottam</option>
          <option onClick={e => updateFeedbackDropdown(e)} value="higher">Magasabbra állítottam</option>
          <option onClick={e => updateFeedbackDropdown(e)} value="lower">Alacsonyabbra állítottam</option>
        </InputSelect>
        <div>
        <InputSelect id="feelText" defaultValue={feedback.feelText}  cta="Éreztél változást?">
            <option onClick={e => updateFeedbackDropdown(e)} value="felt">Éreztem a változást.</option>
            <option onClick={e => updateFeedbackDropdown(e)} value="notFelt">Nem éreztem a hőmérséklet változást.</option>
            <option onClick={e => updateFeedbackDropdown(e)} value="nothing">Nem éreztem semmit.</option>
        </InputSelect>
        </div>
        <div className='pt-10'>
          <span className=' p-5 text-left'>Mennyire érzed azt amit szerettél volna?</span>
          <div className='flex items-center py-5'>
            <label htmlFor="feelSlider" className='w-1/12 text-center'>1</label>
            <input name="feelSlider" className=' w-10/12 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700' id="feelSlider" value={feedback.feelSlider} onChange={e => updateFeedbackData(e)} type="range" min={1} max={5} />
            <label htmlFor="feelSlider" className='w-1/12 text-center'>5</label>
          </div>
        </div>

        <div>
          <span className=' p-5 text-left'>Add meg, hogy milyen hangulatban vagy:</span>
          <div className='flex flex-wrap justify-center'>
            {moods ?
              Object.keys(moods).sort().map(mood => {
                return (
                  <div key={mood} className=' flex flex-col text-center m-1 overflow-hidden h-[80px] w-[80px] rounded-xl flex-wrap'>
                    <input hidden={true} className="peer hidden" type="checkbox" onChange={e => onMoodsChange(e)} value={moods[mood].emoji} name={mood} id={mood}></input>
                    <label className="h-2/3 flex justify-center items-center transition peer-checked:bg-primary-950 text-4xl" htmlFor={mood}  >{moods[mood].emoji}</label>
                    <label className="h-1/3 flex justify-center items-center transition peer-checked:bg-primary-950" htmlFor={mood}  >{moods[mood].name}</label>
                  </div>)
              })
              : <Loader />}
          </div>
        </div>
      </div>
      <Link to={"/"}>
      <button
        disabled={!(feedback.changed && feedback.feelText && feedback.feelSlider)}
        className=""
        onClick={e => { postFeedback(e) }}>Visszajelzés küldése</button>
        </Link>
    </div>

  )
}
