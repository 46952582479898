import React, { } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from '../../Backend/ContextProvider'

export default function GuestLayout() {


  const { userAuth } = useStateContext()


  if (userAuth) {
    return <Navigate to="/dashboard" />
  }
  return (

    <div className='flex flex-col flex-nowrap max-h-screen overflow-y-auto content-stretch items-stretch bg-slate-900'>
      <div className='text-white flex flex-col flex-grow justify-center items-center top-0 left-0'>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
