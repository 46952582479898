import { createContext, useContext, useState } from "react";
import { getData } from "./Firestore";


const StateContext = createContext({
    userAuth: null,
    setUserAuth: () => { },
    refresher: null,
    setRefresher: () => { },
    user: {
        'birthday': {"year": 2016, "month":1, "day": 1},
        'age': null,
        'avatarUrl': null,
        'bodyShape': null,
        'company': null,
        'email': null,
        'env': null,
        'firstName': null,
        'foodAmount': null,
        'frontSens': null,
        'height': null,
        'lastName': null,
        'role': null,
        'gender': null,
        'sweat': null,
        'username': null,
        'password': null,
        'weight': null,
    },
    setUser: () => { },

});

export const ContextProvider = ({ children }) => {
    const [userAuth, setUserAuth] = useState({});
    const [user, setUser] = useState({});
    const [refresher, setRefresher] = useState(true);


    return (
        <StateContext.Provider value={{
            user,
            setUser,
            userAuth,
            setUserAuth,
            refresher,
            setRefresher
        }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = (state) => useContext(StateContext);