import { IconLogout } from '@tabler/icons-react'
import React from 'react'
import { logOut } from '../Backend/FirebaseAuth'
import { LogoSmall } from '../utils/Components/Logo'
import { useStateContext } from '../Backend/ContextProvider'

export default function Header() {

  const { setUser } = useStateContext()

  return (
    <div className='flex flex-nowrap bg-slate-800 justify-between w-screen z-10 p-2 items-center px-6 h-16 relative overflow-hidden '>
      <div className="flex text-white w-1/6 h-full p-1 " ><LogoSmall className="" /></div>
      <div className='font-bold text-white text-xl w-4/6 text-center'>Iroda</div>
      <div className='w-1/6 flex flex-row justify-end text-white '>
        <div className='hover:bg-slate-700 cursor-pointer flex h-full p-4' onClick={() => { logOut(); setUser({"birthday":{year:new Date().getFullYear(), day:1, month:1}}) }}>
          <span className='mr-2'>Kilépés</span><IconLogout className=''  />
        </div>
      </div>
    </div>
  )
}
