import React from 'react'
import Loader from '../../utils/Components/Loader'
import circleLine from "../../assets/circle.svg"

export default function Thermostat(props) {
    return (
        <div className='flex flex-col  justify-center grow items-center'>
            <div className='relative bg-slate-800 aspect-square justify-center min-w-[250px] rounded-full flex flex-col items-center'>
                <img src={circleLine} id='circle' className={'absolute scale-[115%] pointer-events-none'} />
                {props.currentTempData.val && props.modes ?
                    <>
                        <div className='flex flex-col items-center h-full justify-center'>
                            <div className="text-6xl pb-2 px-10">{(props.currentTempData.val).toFixed(1)||null}°</div>
                            <div className="text-xl">{props.modes[props.currentTempData.mode]}</div>
                        </div>
                    </>
                    : <Loader />
                }
            </div>
        </div>
    )
}
