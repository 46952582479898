import { IconMinus, IconPlus } from "@tabler/icons-react";
import React from "react";
import { createDoc } from "../../Backend/Firestore";
import { useStateContext } from "../../Backend/ContextProvider";
import { toast } from "react-toastify";
import { getAuthErrors } from "../../utils/AuthErrors";

export default function Buttons(props) {
  const { user, userAuth } = useStateContext();

  async function changeTemp(value) {
    if (
      props.currentTempData.val + value >= 20 &&
      props.currentTempData.val + value <= 30
    ) {
      if (userAuth.emailVerified) {
        let data = {
          val: props.currentTempData.val + value,
          room: props.currentTempData.room,
          mode: props.currentTempData.mode,
          type: "set_temp",
          timestamp: new Date().getTime(),
          // 'location': user.company
        };
        await createDoc("/temperature", data)
          .then(() => {
            props.setRefresherDashboard(new Date().getTime());
            document
              .getElementById("circle")
              .animate({ rotate: value * 20 + "deg", composite: "add" }, 300);
          })
          .catch((e) => {
            toast.warning("Hiba a hőmérséklet írása közben" + getAuthErrors(e));
          });
      } else {
        toast.warning("Előbb hitelesítsd az email címed.");
      }
    }
  }

  async function changeMode() {
    if (userAuth.emailVerified) {
      let data = {
        timestamp: new Date().getTime(),
        val: parseFloat(props.currentTempData.val),
        room: props.currentTempData.room,
        mode:
          (props.currentTempData.mode + 1) % Object.keys(props.modes).length,
        type: "set_modes",
        location: "user.company",
      };
      props.setRefresherDashboard(new Date().getTime());
      await createDoc("temperature", data, userAuth.uid).then(() => {});
    } else {
      toast.warning("Előbb hitelesítsd az email címed.");
    }
  }

  return (
    <div className="flex flex-col justify-center relative items-center border-primary-light-300 mt-6 w-screen md:w-full p-5">
      <div className="flex justify-around w-full relative gap-2">
        <button
          className="w-1/2 flex justify-center shadow-sm"
          onClick={() => changeTemp(-0.5)}
        >
          <IconMinus size={40} />
        </button>
        <button
          className="w-1/2 flex justify-center shadow-sm"
          onClick={() => changeTemp(0.5)}
        >
          <IconPlus size={40} />
        </button>
      </div>
      <button
        onClick={() => changeMode()}
        className="w-full py-3 text-xl mt-2 shadow-sm"
      >
        Mode
      </button>
    </div>
  );
}
