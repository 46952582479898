import { FirebaseConfig } from "../Backend/firebaseConfig";
import { doc, getFirestore, writeBatch } from "firebase/firestore";
import { deleteUser } from "firebase/auth";
import { useStateContext } from "../Backend/ContextProvider";
import { logOut } from "../Backend/FirebaseAuth";
import { deleteData, getData } from "../Backend/Firestore";
import { toast } from "react-toastify";
import { getAuthErrors } from "./AuthErrors";
import { initializeApp } from "firebase/app";

export async function DeleteLoggedUser(userAuth, setUser, user) {

    const firebaseApp = initializeApp(FirebaseConfig)
    const db = getFirestore(firebaseApp);

    //create batch
    const batch = writeBatch(db)









    //remove user uid from company users list
    batch.update(doc(db, "compnaies", user.company),
        await getData("companies", user.company).then(data => {
            const { [userAuth.uid]: _, ...remainingUsers } = data.users;
            return { ...data, users: remainingUsers };
        }));
    debugger
    //removes user from the users collection
    batch.delete(doc(db, "users", userAuth.uid));



    batch.commit().then(async (da) => {
        console.log(da)
        await deleteUser(userAuth).then(async () => {
            toast.success(`${user.username} nevű felhasználó sikeresen törölve`)
            await setUser({})
            await logOut()
        }).catch((error) => {
            toast.error("Felhasználó törlése közben hiba lépett fel<br/>" + getAuthErrors(error))
        });
    })


}