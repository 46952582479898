import React from 'react'
import { InputText } from '../../utils/Components/InputText'
import { IconEdit } from '@tabler/icons-react'
import { CustomModal } from '../../utils/Components/Modal'


export default function Avatar(props) {

    return (
        <>
            <div className='flex flex-col xs:flex-row items-center xs:mb-4'>
                <div className="w-[125px] relative aspect-square rounded-full inline-block overflow-hidden mx-auto">
                    <img className='' src={props.avatarUrl} />
                    {/* <div className='w-full h-full absolute top-0 left-0 bg-primary-900/70 opacity-0 pointer-events-none peer-hover:opacity-100 transition justify-center items-center flex '>
                        <IconEdit className='cursor-pointer' size={40}></IconEdit>
                    </div> */}
                </div>
                <div className='flex flex-col  w-full xs:ml-2'>
                    <div className='flex flex-col xs:flex-row'>
                        <InputText className="w-full xs:w-1/2 xs:!mb-0 mr-1" onChange={(e) => props.updateUserText(e)} placeholder="Vezetéknév" id={"firstName"} >{props.firstName}</InputText>
                        <InputText className="w-full xs:w-1/2 xs:!mb-0 ml-1" onChange={(e) => props.updateUserText(e)} placeholder="Keresztnév" id={"lastName"} >{props.lastName}</InputText>
                    </div>
                </div>
            </div>
            <CustomModal title="Profilkép szerkesztése">
                
            </CustomModal>
        </>
    )
}
